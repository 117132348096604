import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SideNav from '../sidenav/sidenav'
import cookies from '../../shared/utilities/cookies';
import { Redirect } from 'react-router-dom';

export class header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSideNav: false,
            redirect: false,
            modal: false

        }
    }
    openSideNav = () => {
        this.setState({ showSideNav: true })

    }
    signOut = () => {
        cookies.deleteCookie('token');
        this.setState({ redirect: true });
    }
    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }
    render() {
        if (this.state.redirect == true) {
            return <Redirect to='/' />
        }
        return (
            <React.Fragment>
                <div className="w-100 position-fixed bg-white" style={{
                    height: '50px',
                    boxShadow: '0px 0px 10px 0 rgba(0,0,0,0.3)',
                    top: 0,
                    left: 0,
                    right: 0,
                    zIndex: 1
                }}>
                    <div className="float-right"  >
                        {/* <div class="dropdown"> */}
                        <button
                            style={{ backgroundColor: 'white', border: 0, width: '50px', height: '50px' }} id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fas fa-user"></i>
                        </button>
                        <div className="dropdown-menu dropshadow" style={{ width: '300px', padding: '20px', border: 0, webkitUserSelect: 'none', msUserSelect: 'none', userSelect: 'none' }} aria-labelledby="dropdownMenu2">
                            {cookies.getCookie('user')}
                        </div>
                        {/* </div> */}
                        {/* <button style={{ backgroundColor: 'white', border: 0, width: '50px', height: '50px' }}><i className="fas fa-user"></i>{ cookies.getCookie('user')}</button> */}
                        <button onClick={this.toggle} style={{ backgroundColor: 'white', border: 0, width: '50px', height: '50px' }}><i className="fas fa-sign-out-alt text-center"></i></button>
                    </div>
                </div>


                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.toggle}>Warning</ModalHeader>
                    <ModalBody>
                        Are you sure to Logout?
                    </ModalBody>
                    <ModalFooter>
                        <Button className="gradient-primary3" onClick={this.signOut}>Logout</Button>{' '}
                        <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>

        )
    }
}

export default header
