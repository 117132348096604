import React, { Component } from "react";
import "../../scss/login.css";
import $, { type } from "jquery";
import { connect } from "react-redux";
import { Login } from "../../actions/postActions";
import propsTypes from "prop-types";
import { Redirect } from "react-router-dom";
import cookies from "../../shared/utilities/cookies";
import axios from "axios";
import LoginBanner from "./loginbanner.png";
import LoginBanner2 from "./inventory-management-blog.png";
import LoaderGif from "../../assets/img/loader/loader.svg";
import LtLogo from "./logo.svg";
// import Logo from "../../assets/icon/otto.svg";

let URL = 'https://ottomatically.com';
// let URL = 'http://192.168.1.104:3006';

export class login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      redirect: false,
    };

    // this.handleLogin = this.handleLogin.bind(this);
  }
  componentWillMount() {
    if (cookies.getCookie('token')) {  // get token if user logged in redirect to dashboard
      this.setState({ redirect: true });
    }
  }


  handleLogin = async (event) => {
    console.log("Login Function called ")

    let email = null;
    let password = null;
    email = $("#email").val();
    password = $("#pass").val();
    let credentials = {
      _id: email,
      password: password,
    };
    console.log("credentials ", credentials)

    if (email == '') {
      alert("email should not be empty")
    }
    else if (password == '') {
      alert("password should not be empty")
    }
    else if (email == '' || password == '') {
      alert("email or password should not be empty")
    }
    else {
      // this.props.Login(credentials);
      // let response = this.props.posts; // remove from redux for testing login button delay


      //   const requestOptions = {
      //     method: 'POST',
      //     headers: { 'Content-Type': 'application/json' },
      //     body: JSON.stringify({ _id:'',password:'' })
      // };
      //   fetch(URL + "/inventory-server/api/users/signin", requestOptions)
      //     .then(res => res.json())
      //     .then(data => console.log("data", data))        // for testing response with fetch 



      try {
        let response = await axios.post(URL + "/inventory-server/api/users/signin", credentials)   // Login API
        console.log("Login response ", response);

        if (response !== '') {
          if (response.status === 200) {

            cookies.setCookie("token", response.data.token, response.data.expiresIn);
            cookies.setCookie("user", credentials._id, response.data.expiresIn);
            this.setState({ redirect: true });
          }
          // else if (response.status === 404) {
          //   alert("Incorrect email or password")
          // }

        }
        else {
          alert("Incorrect email or password")
        }
      } catch (error) {
        console.log("Login Error", error.response)
        if (error.response.data.statusCode === 404) {
          alert(error.response.data.message)
        }
      }
    }


    // this.props.Login(credentials);
    // let response = this.props.Login(credentials);
    // try {
    //     let response = await axios.post('https://linked-things-s4-gateway.eu-de.mybluemix.net/api/v1/users/signin', credentials);
    //     console.log("response", response);
    //     cookies.setCookie('token', response.data.token, response.data.expiresIn)
    //     cookies.setCookie('user', credentials._id, response.data.expiresIn)

    //     if (response.status == 200) {
    //         this.setState({ redirect: true })
    //     }

    // } catch (err) {
    //     err.response.data.error = true;
    //     return err.response.data;
    // }
  };
  render() {
    if (this.state.redirect == true) {
      return <Redirect to="/dashboard" />;
    }


    return (
      <div className="container py-3 px-5 px-md-0 px-lg-5 d-flex" style={{ minHeight: '100vh' }}>
        {/* <ToastPopup toastData={{'type':'success','head':'Success','msg':'New changes has been updated successfully'}}/> */}
        {/* <ToastPopup toastData={{ 'type': 'info', 'head': 'Info', 'msg': 'Underground motor has been started' }} /> */}
        {/* <ToastPopup toastData={{'type':'warning','head':'Warning','msg':'Some Devices are not working'}}/> */}
        {/* <ToastPopup toastData={{'type':'danger','head':'Error','msg':'Data has been deleted'}}/> */}
        <div className="bg-white dropshadow d-flex align-self-center rounded-1 p-0 overflow-hidden">
          <div className="row m-0">
            <div className="col-6 py-5 d-none d-md-flex"
              style={{
                backgroundImage: "linear-gradient(-45deg, #0D3A5D, #1B75BC)"
              }}
            >
              <div className="row justify-content-center align-self-center">
                <img
                  src={LoginBanner2}
                  className="col-10"
                  style={{ marginBottom: "20px" }}
                />
                <h4 className="col-12 text-center text-light">IoT as a Service</h4>
                <p className="col-10 text-center text-light">
                  SAVING - PRODUCTIVITY - INNOVATION
                </p>
                <div className="col-12 d-flex justify-content-center">
                  <button
                    className="btn btn-light border-0 m-1 dropshadow"
                    onClick={() => window.open('https://www.linked-things.com', '_blank')}
                  // style={{
                  //   backgroundImage: "linear-gradient(45deg, #0D3A5D, #1B75BC)",
                  // }}
                  >
                    LEARN MORE
                  </button>
                  {/* <button
                    className="btn btn-light border-0 m-1 dropshadow"
                    onClick={() => this.props.history.push('/signup')}
                    // style={{
                    //   backgroundImage: "linear-gradient(45deg, #0D3A5D, #1B75BC)",
                    // }}
                  >
                    Signup
                  </button> */}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12 pt-5 d-flex">
              <div className="row d-flex justify-content-center align-self-center">
                <div className="col-12">
                  <center>
                    <img width="100px" src={LtLogo}
                    // style={{ filter: "brightness(10)" }}
                    ></img>
                    <h3 className="text-center m-0 my-3"
                      style={{
                        color: "black"
                      }}>
                      OTTOMATICALLY
                    </h3>
                  </center>
                </div>
                <div className="col-12 px-5">
                  <center>
                    <form>
                      <input
                        id="email"
                        className="form-control rounded-1 mb-3"
                        placeholder="Email"
                        type="email"
                        style={{
                          borderColor: "#1B75BC"
                        }}
                        required

                      />
                      <input
                        id="pass"
                        className="form-control rounded-1 mb-3"
                        placeholder="Password"
                        type="password"
                        style={{
                          borderColor: "#1B75BC"
                        }}
                        required

                      />
                      <a
                        className="col-12"
                        href=""
                      >
                        Forgot your password?
                      </a>
                      {(this.props.isLoader) ?

                        <img src={LoaderGif} className="w-100 m-1" style={{ height: '50px' }} />

                        :
                        <button
                          type="button"
                          className="col-8 btn-lg mt-3 border-0 btn-dark dropshadow"
                          style={{
                            borderRadius: "50px",
                            backgroundImage: "linear-gradient(-45deg, #0D3A5D, #1B75BC)",
                          }}
                          onClick={this.handleLogin}
                        >
                          Sign In
                        </button>
                      }
                    </form>
                  </center>
                </div>
                <div className="col-12 d-flex justify-content-center">
                  <div className="mt-4 bg-white p-3 rounded">
                    <img src={LtLogo} height={30} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  posts: state.posts.item,
});

login.propsTypes = {
  Login: propsTypes.func.isRequired,
};

export default connect(mapStateToProps, { Login })(login);
