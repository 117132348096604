import moment from 'moment';

export function getUnit(deviceType) {
    if (deviceType === 'TVOC' || deviceType === 'LPG' || deviceType === 'CH4' || deviceType === 'Humidity' || deviceType === 'Oxygen') {
        return '%'
    }
    else if (deviceType === 'Temperature') {
        return '°C'
    }
    else if (deviceType === 'CO' || deviceType === 'O3' || deviceType === 'SO2' || deviceType === 'NO2') {
        return 'ppm'
    }
    else if (deviceType === 'Pressure') {
        return 'hPa'
    }
    else if (deviceType === 'Hydpressure') {
        return 'bar'
    }
    else if (deviceType === 'Dust') {
        return 'µg/m3'
    }
    else if (deviceType === 'Altitude') {
        return 'Meters'
    }
    else if (deviceType === 'AQI') {
        return 'INDEX'
    }

}


export function getDate(str) {
    //console.log("Function getDate",str)
    let date = str.split('T')[0];
    let splitDate = date.split('-');
    return splitDate[2] + '-' + splitDate[1] + '-' + splitDate[0]
}


export let getSortedDate = (dates = []) => {
    if (dates.length > 1) {
        var i, j;
        for (j = 0; j < dates.length - 1; j++) {
            for (i = 0; i < dates.length - 1; i++) {
                if (moment(dates[i], 'YYYY-MM-DD[T]HH:mm:ss').isBefore(dates[i + 1])) {
                    dates.splice(i, 2, dates[i + 1], dates[i])
                }
            }
        }
    }
    return dates[0]
}

export let combineObjectsintoOne = (obj = {}) => {
    let keys = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j"]
    let value = "name"
    let finalObj = {}
    if (obj.length > 0) {
        obj.map((ob, ind) => {
            finalObj[keys[ind]] = ob[value]
        })
    }
    return finalObj
}

export function getWordCount(str) {
    return str.length
}

export function getTime(str) {
    // console.log("Function getTime",str)
    let time = str.split('T')[1];
    let timeFiltered = time.split('.')[0]
    let splitTime = timeFiltered.split(':');
    return formatAMPM(splitTime[0], splitTime[1], splitTime[2])
}
function formatAMPM(hours, minutes, secs) {
    var hours = hours
    var minutes = minutes
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    if (minutes === 0) {
        minutes = "00"
    }
    else {
        minutes = minutes
    }
    //  minutes = minutes < 10 ? ''+minutes : minutes;
    var strTime = hours + ':' + minutes + ':' + secs + " " + ampm;
    return strTime;
}

function formatAMPMHours(hours, minutes, secs) {
    var hours = hours
    var minutes = minutes
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    if (minutes === 0) {
        minutes = "00"
    }
    else {
        minutes = minutes
    }
    //  minutes = minutes < 10 ? ''+minutes : minutes;
    var strTime = hours + " " + ampm;
    return strTime;
}

export function convertToInt(date, start = null, end = null) {
    let dt = date

    dt = dt.replace('T', ' ');
    if (dt.length === 16) {
        dt += ':00.000';
    }
    else if (dt.length === 19) {
        dt += '.000';
    }
    else if (dt.length === 10) {
        dt += ' 00:00:00.000';
    }

    return dt
}

export function convertToDate(str, type) {
    let date = str.split('T')[0];
    let splitDate = date.split('-');
    let time = str.split('T')[1];
    let timeFiltered = time.split('.')[0]
    let splitTime = timeFiltered.split(':');

    let day = splitDate[2]
    let month = splitDate[1]
    let year = splitDate[0]
    let hours = splitTime[0]
    let mins = splitTime[1]
    let secs = splitTime[2]
    if (type === 'day') {
        return hours;
    }
    else if (type === 'month') {
        return day;
    }

    else if (type === 'year') {
        return month;
    }

    else if (type === 'live') {
        return formatAMPM(hours, mins, secs);
    }

    else if (type === 'hours') {
        return formatAMPMHours(hours, mins, secs);
    }

    else if (type === 'recent') {
        return hours;
    }

    else if (type === 'date') {
        return day + '-' + month + '-' + year;
    }

    else if (type === 'object') {
        return [year, month, day, hours, mins, secs];
    }

    return formatAMPM(hours, mins, secs) + ' ' + day + '-' + month + '-' + year;
    //return new Date(year, month, day, hours, mins, secs);
}





export function getState(device, status, sn, graphType) {

    if (device.type === 'AC') {
        if (sn === 1) {
            if (status === 0) {
                return 'Status : OFF';
            }
            else {
                return 'Status : ON';
            }
        }

        else if (sn === 2) {
            if (status === 0) {
                return 'Swing : OFF';
            }
            else {
                return 'Swing : ON';
            }
        }

        else {
            return 'Temperature : ' + ' ' + status + ' °C'
        }
    }

    else if (device.type === 'Fan' || device.type === 'Light' || device.type === "Monitor") {
        if (status === 0) {
            return 'Status : OFF';
        }
        else {
            return 'Status : ON';
        }
    }

    else if (device.type === 'Door' || device.type === 'Lock') {
        if (status === 0) {
            return 'Status : CLOSE';
        }
        else {
            return 'Status : OPEN';
        }
    }

    else if (device.type === 'Leakage') {
        if (status === 0) {
            return 'Status : NO LEAKAGE';
        }
        else {
            return 'Status : LEAKAGE';
        }
    }

    else if (device.type === 'Curtain') {
        if (status === 0) {
            return 'Status : CLOSE';
        }
        else if (status === 1) {
            return 'Status : MID';
        }
        else {
            return 'Status : OPEN';
        }
    }

    else if (device.type === 'Sound' || device.type === 'Fire' || device.type === 'Smoke') {
        if (status === 0) {
            return 'Status : NO ' + device.type.toUpperCase();
        }
        else {
            return 'Status : ' + device.type.toUpperCase();
        }
    }

    else if (device.type === 'Humidity') {
        return device.name + ': ' + status + '' + ' %';
    }

    else if (device.type === 'Temperature') {
        if (device.metadata)
            if (device.metadata.unit)
                return device.name + ': ' + status + ' ' + device.metadata.unit;
            else
                return device.name + ': ' + status + ' °C';
    }

    else if (device.type === 'Count') {
        if (device.metadata)
            if (device.metadata.unit)
                return device.name + ': ' + status + ' ' + device.metadata.unit;
            else
                return device.name + ': ' + status + ' ';


    }


    else if (device.type === 'Motion') {
        if (status === 0) {
            return 'Status : NO MOVEMENT';
        }
        else {
            return 'Status : MOVEMENT';
        }
    }
    else if (device.type === 'FuelTank') {
        return 'Fuel Level : ' + status + ' %'
    }
    else if (device.type === 'Battery') {
        return 'Battery Level : ' + status + ' %'
    }
    else if (device.type === 'SourceIdentifier') {
        if (status === 0) {
            return 'Status : OFF';
        }
        else {
            return 'Status : ON';
        }
    }
    else if (device.type === 'WaterTank') {
        if (device.metadata) {
            if (device.metadata.monitor) {
                if (device.metadata.monitor === 'Oil') {
                    return 'Oil Level : ' + status + ' %';
                } else {
                    let unit = device.metadata.unit ? device.metadata.unit : "%"
                    return 'Water Level : ' + status + unit;
                }
            } else {
                let unit = device.metadata.unit ? device.metadata.unit : "%"
                return 'Water Level : ' + status + unit;
            }
        } else {
            let unit = device.metadata.unit ? device.metadata.unit : "%"
            return 'Water Level : ' + status + unit;
        }
    }

    else if (device.type === 'Moisture') {
        return ('Moisture : ' + status + ' %')
    }
    else if (device.type === 'Pressure') {
        if (device.metadata)
            if (device.metadata.unit)
                return ('Pressure : ' + status + ' ' + device.metadata.unit);
            else
                return ('Pressure : ' + status + ' hPa');
    }
    else if (device.type === 'Hydpressure') {
        return ('Hydraulic Pressure : ' + status + ' bar')
    }
    else if (device.type === 'FlowMeter') {
        return ('Flow Meter : ' + status + ' L/min')
    }
    else if (device.type === 'Noise') {
        return ('Noise : ' + status + ' dB')
    }

    else if (device.type === 'Gust' || device.type === 'WindSpeed') {
        return (device.type + ' : ' + status + 'm/s')
    }
    else if (device.type === 'WindDirection') {
        return (device.type + ' : ' + status + '°')
    }
    else if (device.type === 'Rain') {
        return (device.type + ' : ' + status + ' mm')
    }
    else if (device.type === 'Illuminance') {
        return (device.type + ' : ' + status + ' LUX')
    }
    else if (device.type === 'UV') {
        return (device.type + ' : ' + status + ' UW/cm2')
    }
    else if (device.type === 'SolarRadiation') {
        return (device.type + ' : ' + status)
    }
    else if (device.type === 'DewPoint') {
        return ('DewPoint : ' + status + ' °C')
    }
    else if (device.type === 'PhIndicator') {
        return ('Ph : ' + status)
    }

    else if (device.type === 'Vibration') {
        if (status === 0) {
            return 'Status : NO VIBRATION';
        }
        else {
            return 'Status : VIBRATION';
        }
    }


    else if (device.type === 'Position') {
        if (status === 0) {
            return 'Status : NORMAL';
        }
        else {
            return 'Status : TILT';
        }
    }


    else if (device.type === 'Dust' || device.type === 'CO' || device.type === 'CO2' || device.type === 'CH4' || device.type === 'LPG' || device.type === 'TVOC' || device.type === 'AQI' || device.type === 'Altitude' || device.type === 'Oxygen') {
        if (device.type === 'Dust') {
            return (device.type + ' : ' + status + ' µg/m')
        }
        if (device.type === 'CO' || device.type === 'CO2' || device.type === 'CH4' || device.type === 'O3' || device.type === 'SO2' || device.type === 'NO2') {
            return ('' + status + ' ppm')
        }
        else if (device.type === 'AQI') {
            return (device.type + ' : ' + status)
        }
        else if (device.type === 'Altitude') {
            return (device.type + ' : ' + status + ' Meters')
        }
        else {
            return (device.type + ' : ' + status + ' %')
        }


    }

    else if (device.type === 'PDU' || device.type === 'UPS' || device.type === 'Meter') {
        if (graphType === 'Current') {
            return '' + status + ' Ampere'
        }
        else if (graphType === 'Voltage') {
            return '' + status + ' Volts'
        }
        else if (graphType === 'Power') {
            return '' + status + ' KWatts'
        }
        else if (graphType === 'Energy') {
            return '' + status + ' Kw/Hr'
        }
        else if (graphType === 'Load') {
            return '' + status + ' %'
        }
    }
}

export function getDevice(hvalue, devices) {
    let temp = null;
    devices.map((dvalue) => {
        if (hvalue._id === dvalue.levelId) {
            temp = dvalue
        }
    })
    return temp
}

export function getDevices(hvalue, devices) {
    let temp = [];
    devices.map((dvalue) => {
        if (hvalue._id === dvalue.levelId) {
            temp.push(dvalue)
        }
    })
    return temp
}

export function createDate() {
    let temp = moment().utc().add(5, 'hours')
    return temp
}
export function toTitles(s) { return s.replace(/\w\S*/g, function (t) { return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase(); }); }