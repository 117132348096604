import React, { Component } from 'react'
import { connect } from "react-redux";
import { fetchList, Update } from '../../actions/postActions'
import PropsTypes from "prop-types";
import cookies from '../../shared/utilities/cookies'
import Header from '../header/header'
import SideNav from '../sidenav/sidenav'
import Footer from '../footer/footer'
import ConfirmPassword from '../popup/confirmPassword'
import LoaderGif from '../../assets/img/loader/loader.svg'
import axios from 'axios';
import DataTable from 'datatables.net';
import $, { data } from 'jquery';
import { Redirect } from "react-router-dom";
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import Pagination from '@material-ui/lab/Pagination';
import TextField from '@material-ui/core/TextField';
let link = [];
let short = [];
// let URL = 'https://24b65d314f8b.ngrok.io/inventory-server/api/product/';
let URL = 'https://ottomatically.com/inventory-server/api/product/';
let res1 = '';

export class dashboard extends Component {
    constructor(props) {
        super(props);
        // this.quantity = this.state.quantity.bind(this);
        this.state = {
            getlimit: 10,
            link: [],
            short: [],
            showPassword: false,
            deleteId: '',
            popup: null,
            fetchObj: {},
            verif_val: null,
            filterdocs: [],
            docs: [],
            imagePath: '',
            loader: false,
            tableLoader: false,
            pagination: [],
            selectedPage: { start: 0, end: 10 },
            redirect: false,
            Pages: 0
        }

        $(document).ready(function () {
            $('#dtBasicExample').DataTable();
            $('.dataTables_length').addClass('bs-select'); // for implement data tables to bootstrap table
        });
    }

    linkEdit = () => {
        // alert(event.target.nodeName);

    }

    async componentWillMount() {
        if (cookies.getCookie('token')) {    // get token if user logged in get the products
            this.setState({ loader: true });
            console.log("props", this.props);
            this.props.fetchList();
            this.state.fetchObj = this.props.posts
            this.setState({
                fetchObj: this.state.fetchObj
            })
        }
        else {
            this.setState({ redirect: true });
        }


        //     this.setState({ loader: true });
        //     this.props.fetchList();
    }
    async componentWillReceiveProps(nextProps) {

        $(document).ready(function () {          // for edit links of inventory
            $('.linkEdit').on('click', function () {
                // alert("hello");
                var parent = $(this).parent();
                var that = $(this).siblings('a');
                console.log(that.attr("href"));
                var $input = $('<input>').val(that.attr('href'))
                    .css({
                        'display': 'none',
                        'width': parent.width(),
                        'height': parent.height(),
                        'padding': '10px'
                    });

                parent.append($input);
                that.fadeOut(0);
                parent.find('input').fadeIn(300);
                $input.blur(function (event) {
                    // if (!$(event.target).closest('.editable').length) {
                    if ($input.val()) {
                        that
                            .text($input.val().split('/')[2])
                            .attr('href', $input.val());

                        let fieldNameId = parent.attr('data-id');
                        let fieldNameLink = parent.attr('data-field');
                        let newDataLink = JSON.parse('{"links":{"' + fieldNameLink + '":"' + $input.val().trim() + '"}}')
                        try {
                            let token = cookies.getCookie('token');
                            axios.defaults.headers['Authorization'] = 'Bearer ' + token;
                            let response = axios.put(URL + fieldNameId + '/update', newDataLink);
                            console.log("response", response)
                        } catch (error) {
                            console.log("Dashboard Error  :", error.response)
                        }

                    }
                    that.fadeIn(300);
                    $input.remove();
                    // }
                });
            })
        });

        if (this.state.docs !== nextProps.posts) {
            // this.setState({ docs: nextProps.posts }, () => console.log("this.state.docs", this.state.docs)
            // );

            this.state.docs = nextProps.posts
            this.state.filterdocs = nextProps.posts
            this.Pagination()
            // $(document).ready(function () {
            //     // $('#dtBasicExample').DataTable();
            //     $('.dataTables_length').addClass('bs-select');
            // });

            // let token = cookies.getCookie('token');
            // axios.defaults.headers['Authorization'] = 'Bearer ' + token;

            // res1 = await axios.get(URL);
            // console.log("All Products", res1.data.result.docs);
            // res1.data.result.docs.map(item => this.state.docs.push(item));
            // // this.state.docs.push(res1.data.result.docs);
            // console.log("after push", this.state.docs);
        }
        this.setState({ loader: false });
        this.setState({ tableLoader: false });
    }

    // getDataQuery = async (page, perpage) => {   // for own pagination bar
    //     let token = cookies.getCookie('token');
    //     axios.defaults.headers['Authorization'] = 'Bearer ' + token;
    //     let response = await axios.get(URL, {
    //         params: {
    //             page: page,
    //             perpage: perpage
    //         }
    //     });
    //     console.log("data Response", response.data.result);
    //     this.setState({
    //         pagination: response.data.result
    //     }, () => console.log("pagination", response.data.result))

    // }

    Update = async (id, updateVal, fieldName, type) => {  // for updating products fields in table
        this.setState({ tableLoader: true });
        var newUpdate = null;
        let int = true
        if (fieldName === 'receivedAt') {
            newUpdate = $('#' + updateVal).val();
            console.log('newUpdate', $('#' + updateVal).val());
        }
        else {
            console.log("else1");
            newUpdate = $('#' + updateVal).html();
        }

        console.log(fieldName + ": " + newUpdate, this.state.docs);

        this.state.docs.map((events, key) => {
            if (events._id == id) {
                this.state.docs[key][fieldName] = newUpdate
                console.log(events, fieldName, this.state.docs, newUpdate)
                this.setState({ docs: this.state.docs })
            }
        })
        let newData = "";
        if (newUpdate != null && fieldName !== 'receivedAt') {
            newUpdate = newUpdate.trim();
        }

        if (
            fieldName === 'productQuantity.total' || fieldName === 'received' || fieldName === 'available' || fieldName === 'productQuantity.updated' || fieldName === 'productQuantity.previous' || fieldName === 'productQuantity.used' || fieldName === 'productQuantity.faulty'
        ) {
            // newUpdate = parseInt(newUpdate)
            // let newUpdateOld = parseInt(newUpdate);
            // newUpdate = newUpdateOld;
            if ((parseInt(newUpdate).toString()).length !== newUpdate.length) {
                int = false
                $('#' + updateVal).val()
            }
            let splitedfield = fieldName.split('.');
            console.log("productQuantity", splitedfield);
            newData = JSON.parse('{"' + splitedfield[0] + '":{"' + splitedfield[1] + '":"' + newUpdate + '"}}');
            console.log("Product Data", newData);
        }
        if (fieldName === 'location') {
            newData = JSON.parse('{"' + fieldName + '":"' + newUpdate + '"}')
        }
        if (fieldName === 'partNumber') {
            newData = JSON.parse('{"' + fieldName + '":"' + newUpdate + '"}')
        }
        if (fieldName === 'image') {
            newData = this.state.imagePath;
        }

        else {
            console.log("else2");
            newData = JSON.parse('{"' + fieldName + '":"' + newUpdate + '"}')
        }
        // this.setState({ tableLoader: false });
        // let token = cookies.getCookie('token');
        // axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        // let response = await axios.put('https://ottomatically.com/inventory-server/api/product/' + id + '/update', newData);
        if (int) {
            this.props.Update(id, newData, fieldName);
            let response = this.props.update;
            console.log("Update response", response)
        } else {
            alert("Only Numeric values allowed")
        }


    }

    delete = async (id) => {
        this.setState({ deleteId: id });
        this.setState({ popup: true });

    }

    changePopup = async (value, validaition) => {  // for delete particular product on popup
        this.setState({ popup: value })
        if (validaition === true) {
            try {
                console.log("deleted", this.state.deleteId)
                let token = cookies.getCookie('token');
                axios.defaults.headers['Authorization'] = 'Bearer ' + token;
                let response = await axios.delete(URL + this.state.deleteId + '/delete');
                console.log("delete Response", response);

                alert("Deleted Successfully");


                // if (response == null) {
                //     alert("Authorization denied, only Admins");
                // }
                // else {
                // alert("Deleted Successfully");
                // }
            } catch (error) {
                console.log("Delete Error", error.response)
                alert(error.response.data.message)
            }
        }
    }
    searchDocs = (e) => {
        let filterdocs = [],
            getVal = e.target.value;
        if (this.state.docs.length !== 0) {
            console.log("object", this.state.docs)
            filterdocs = this.state.docs.filter(doc => {
                return getVal !== "" ? doc.name.toLowerCase().includes(getVal.toLowerCase()) || doc.partNumber.toLowerCase().includes(getVal.toLowerCase()) || doc.location.toLowerCase().includes(getVal.toLowerCase()) : doc;
            })
            this.state.filterdocs = filterdocs;
            this.setState({
                filterdocs: this.state.filterdocs
            })
            this.Pagination()
        }
    }
    limit = (e) => {
        this.state.getlimit = parseInt(e.target.value)
        this.Pagination()
    }
    // table = usePagination({
    //     count: this.pagination,
    // });
    getFile = (event) => {     // for update or add picture of a product
        // this.setState({imagePath:null});
        this.setState({ imagePath: event.target.files[0] })
    }
    Pagination = () => {
        let limit = parseInt(this.state.getlimit)
        let Page = parseInt(Math.floor((this.state.filterdocs.length / limit)))
        let lastBtn = this.state.filterdocs.length - (Page * limit)
        let pagination = [];
        var i = 0;
        for (i = 0; i <= Page; i++) {
            pagination.push(i * limit)
        }
        if (lastBtn) {
            let last = pagination[pagination.length - 1] + lastBtn;
            pagination.push(last)
            Page += 1;
        }
        console.log(this.state.filterdocs.length, Page, pagination, lastBtn, this.state.selectedPage.end)
        this.setState({
            docs: this.state.docs,
            getlimit: this.state.getlimit,
            filterdocs: this.state.filterdocs,
            selectedPage: { start: 0, end: this.state.getlimit },
            pagination,
            Pages: Page
        })

    }
    render() {
        if (this.state.redirect == true) {
            return <Redirect to="/" />;
        }
        return (
            this.state.loader ?
                <img src={LoaderGif} className="w-100 m-1" style={{ position: 'fixed', top: '35%', height: '50%', width: '50%' }} />
                :
                <div className="d-flex">
                    {(this.state.popup) ? <ConfirmPassword popup={this.changePopup} /> : null}
                    <SideNav />
                    <div style={{ width: "100%" }}>
                        <Header />
                        <div className="p-3" style={{ marginTop: '50px' }}>
                            <div className="row">
                                <div className="col-md-3 col-6">
                                    <div className="form-group MuiFilledInput-root">
                                        <select className="MuiInputBase-input MuiFilledInput-input"
                                            id="filled-full-width"
                                            style={{ width: "calc(100% - 30px)" }}
                                            onChange={(e) => this.limit(e)}
                                            defualtValue={10}
                                            name={"Item"}
                                        >
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                        </select>
                                    </div>
                                    {/* <FormGroup>
                                        <Autocomplete filterOptions={this.filterOptions}

                                            options={this.Quantity}
                                            getOptionLabel={(option) => option}

                                            style={{ width: "50%" }}
                                            onSelect={(e) => this.quantity(e)}
                                            renderInput={(params) =>
                                                <TextField {...params}
                                                    label={"Quantity Item"}
                                                    variant="filled"
                                                    defualtValue={10}

                                                    name={"Item"} />
                                            }

                                        />
                                    </FormGroup> */}
                                </div>
                                <div className="col-md-9 col-6">
                                    <TextField
                                        className="m-0"
                                        id="filled-full-width"
                                        label="Items"
                                        style={{ margin: 8 }}
                                        placeholder="Search Item"
                                        autocomplete="false"
                                        fullWidth
                                        margin="normal"
                                        onChange={(e) => this.searchDocs(e)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="filled"
                                    />
                                </div>
                            </div>
                            <div >
                                <table className="table table-bordered table-sm" cellSpacing="0" width="100%">
                                    <thead>
                                        <tr className="gradient-primary3">
                                            <th style={{ fontSize: '14px', textAlign: 'center', color: 'white', minwidth: '20px', maxWidth: '20px' }} scope="col">ID</th>
                                            <th style={{ fontSize: '14px', textAlign: 'center', color: 'white', minwidth: '80px' }} scope="col">Component Name</th>
                                            <th style={{ fontSize: '14px', textAlign: 'center', color: 'white', width: '50px' }} scope="col">Comp. No.</th>

                                            {
                                                (this.state.docs[0].price) ?
                                                    <th style={{ fontSize: '14px', textAlign: 'center', color: 'white', width: '40px' }} scope="col">Price</th>
                                                    :
                                                    null
                                            }




                                            {/* <th style={{ fontSize: '14px', textAlign: 'center', color: 'white' }} scope="col">Available</th> */}
                                            <th style={{ fontSize: '14px', textAlign: 'center', color: 'white', width: '100px' }} scope="col">Picture</th>
                                            <th style={{ fontSize: '14px', textAlign: 'center', color: 'white', width: '100px' }} scope="col">Location</th>
                                            {/* <th style={{ fontSize: '14px', textAlign: 'center', color: 'white' }} scope="col">Updated Quantity</th> */}
                                            {/* <th style={{ fontSize: '14px', textAlign: 'center', color: 'white', width: '100px' }} scope="col">Last Updated Date</th> */}


                                            <th style={{ fontSize: '14px', textAlign: 'center', color: 'white', minWidth: '376px', maxWidth: '376px' }} scope="col">Product Quantity</th>

                                            <th style={{ fontSize: '14px', textAlign: 'center', color: 'white' }} scope="col">Links</th>

                                            {/* <th style={{ fontSize: '14px', textAlign: 'center', color: 'white' }} scope="col">Received Quantity</th> */}

                                            {/* <th style={{ fontSize: '14px', textAlign: 'center', color: 'white', width: '10px' }} scope="col">Date</th> */}

                                            <th style={{ fontSize: '14px', textAlign: 'center', color: 'white', width: '10px' }} scope="col"></th>
                                            {/* Action */}
                                        </tr>
                                    </thead>
                                    {this.state.tableLoader ?
                                        <div style={{ height: "400px" }}>
                                            <img src={LoaderGif} className="w-100 m-1" style={{ position: 'fixed', top: '35%', height: '25%', width: '25%' }} />

                                        </div> :
                                        <tbody>
                                            {
                                                this.state.filterdocs.slice(this.state.selectedPage.start, this.state.selectedPage.end).map((data, index) =>
                                                    <tr>
                                                        <th scope="row"
                                                            style={{ verticalAlign: ' middle' }}>{this.state.selectedPage.start + index + 1}</th>
                                                        <td contentEditable="true"
                                                            suppressContentEditableWarning={true}
                                                            style={{ verticalAlign: ' middle' }}
                                                            id={"name_" + data._id}
                                                            onBlur={() => this.Update(data._id, "name_" + data._id, 'name')}
                                                        >{data.name}</td>

                                                        <td contentEditable="true"
                                                            suppressContentEditableWarning={true}
                                                            style={{ verticalAlign: ' middle' }}
                                                            id={"part_" + data._id}
                                                            onBlur={() => this.Update(data._id, "part_" + data._id, 'partNumber')}
                                                        >{data.partNumber} </td>



                                                        {(this.state.docs[0].price) ?
                                                            <td contentEditable="true"

                                                                suppressContentEditableWarning={true}
                                                                style={{ verticalAlign: ' middle' }}
                                                                id={"price_" + data._id}
                                                                onBlur={() => this.Update(data._id, "price_" + data._id, 'price')}
                                                            >{data.price} </td>
                                                            : null
                                                        }


                                                        {/* <td contentEditable="true"
                                                    suppressContentEditableWarning={true}
                                                    style={{ verticalAlign: ' middle' }}
                                                    id={"avail_" + data._id}
                                                    onBlur={() => this.Update(data._id, "avail_" + data._id, 'available')}
                                                >{data.available} </td> */}

                                                        <td contentEditable="true"
                                                            suppressContentEditableWarning={true}
                                                            style={{ verticalAlign: ' middle' }}
                                                            id={"image_" + data._id}
                                                            // onChange={this.getFile}
                                                            onChange={this.getFile}

                                                            onBlur={() => this.Update(data._id, "image_" + data._id, 'image')}
                                                        >
                                                            <label hmtlFor="exampleFormControlFile1">
                                                                {data.productImage ?
                                                                    <img width="100px "
                                                                        src={'https://ottomatically.com/inventory-server/images/' + data.productImage} alt="Product Name" />
                                                                    :
                                                                    "No Image"
                                                                }
                                                                <input className="bg-danger" type="file" className="form-control-file d-none" id="exampleFormControlFile1"
                                                                />
                                                            </label>
                                                        </td>

                                                        <td contentEditable="true"
                                                            suppressContentEditableWarning={true}
                                                            style={{ verticalAlign: ' middle' }}
                                                            id={"location_" + data._id}
                                                            onBlur={() => this.Update(data._id, "location_" + data._id, 'location')}
                                                        >{data.location}
                                                        </td>

                                                        {/* <td
                                                    style={{ verticalAlign: ' middle' }}
                                                // id={"updatedAt_" + data._id}
                                                // onBlur={() => this.Update(data._id, "updatedAt_" + data._id, 'updatedAt')}
                                                >{moment(data.updatedAt).utc().format("DD-MM-YYYY HH:MM:SS")}</td> */}



                                                        <td className="p-0">
                                                            <table width="100%">
                                                                {/* <tr>
                                                            <td className="p-0"> */}
                                                                <tr>
                                                                    <th style={{ fontSize: '12px', minWidth: '100px' }}>Available</th>

                                                                    <td style={{ minWidth: '35px', maxWidth: '35px' }} contentEditable="true"
                                                                        suppressContentEditableWarning={true}
                                                                        style={{ verticalAlign: ' middle' }}
                                                                        id={"avail_" + data._id}

                                                                        onBlur={() => this.Update(data._id, "avail_" + data._id, 'available', "int")}

                                                                    >{data.available}
                                                                    </td>

                                                                    <td width="200px"
                                                                        style={{ verticalAlign: ' middle' }}
                                                                    // id={"updatedAt_" + data._id}
                                                                    // onBlur={() => this.Update(data._id, "updatedAt_" + data._id, 'updatedAt')}
                                                                    > {(data.availableAt == '') ?
                                                                        "-"
                                                                        :
                                                                        moment(data.availableAt).utc().add(5, 'hours').format("LLL")
                                                                        }
                                                                        {/* moment(data.availableAt).utc().add(5, 'hours').format("LLL") */}
                                                                    </td>
                                                                </tr>

                                                                <tr><th style={{ fontSize: '12px', minWidth: '100px' }}>Received  </th>
                                                                    {/* <td contentEditable="true"
                                                                            suppressContentEditableWarning={true}
                                                                            id={"updated_" + data._id}
                                                                            onBlur={() => this.Update(data._id, "updated_" + data._id, 'productQuantity.updated')}>{data.productQuantity.updated}</td> */}
                                                                    <td style={{ minWidth: '35px', maxWidth: '35px' }} contentEditable="true"
                                                                        suppressContentEditableWarning={true}
                                                                        style={{ verticalAlign: ' middle' }}
                                                                        id={"received_" + data._id}
                                                                        onBlur={() => this.Update(data._id, "received_" + data._id, 'received')}
                                                                    >
                                                                        {data.received}
                                                                    </td>

                                                                    <td
                                                                        style={{ verticalAlign: ' middle' }}
                                                                    // id={"updatedAt_" + data._id}
                                                                    // onBlur={() => this.Update(data._id, "updatedAt_" + data._id, 'updatedAt')}
                                                                    >
                                                                        {/* {moment(data.receivedAt).utc().add(5, 'hours').format("LLL")} */}
                                                                        {(data.receivedAt == '') ?
                                                                            "-"
                                                                            :
                                                                            moment(data.receivedAt).utc().add(5, 'hours').format("LLL")
                                                                        }

                                                                    </td>
                                                                </tr>

                                                                {/* //changed the name previous to Issued Internally  */}
                                                                <tr><th style={{ fontSize: '12px', minWidth: '100px' }} >Issued Internally </th>
                                                                    <td style={{ minWidth: '35px', maxWidth: '35px' }} contentEditable="true"
                                                                        suppressContentEditableWarning={true}
                                                                        style={{ verticalAlign: ' middle' }}
                                                                        id={"previous_" + data._id}
                                                                        onBlur={() => this.Update(data._id, "previous_" + data._id, 'productQuantity.previous')}
                                                                        onChange={() => { }}
                                                                    >{data.productQuantity.previous}
                                                                    </td>

                                                                    <td
                                                                        style={{ verticalAlign: ' middle' }}
                                                                    // id={"updatedAt_" + data._id}
                                                                    // onBlur={() => this.Update(data._id, "updatedAt_" + data._id, 'updatedAt')}
                                                                    >{(data.productQuantity.previousUpdatedAt == '') ?
                                                                        "-"
                                                                        :
                                                                        moment(data.productQuantity.previousUpdatedAt).utc().add(5, 'hours').format("LLL")
                                                                        }

                                                                    </td>
                                                                </tr>

                                                                <tr><th style={{ fontSize: '12px', minWidth: '100px' }} >Used</th>
                                                                    <td style={{ minWidth: '35px', maxWidth: '35px' }} contentEditable="true"
                                                                        suppressContentEditableWarning={true}
                                                                        style={{ verticalAlign: ' middle' }}
                                                                        id={"used_" + data._id}
                                                                        onBlur={() => this.Update(data._id, "used_" + data._id, 'productQuantity.used')}>{data.productQuantity.used}
                                                                    </td>

                                                                    <td
                                                                        style={{ verticalAlign: ' middle' }}
                                                                    // id={"updatedAt_" + data._id}
                                                                    // onBlur={() => this.Update(data._id, "updatedAt_" + data._id, 'updatedAt')}
                                                                    >{(data.productQuantity.usedUpdatedAt == '') ?
                                                                        "-"
                                                                        :
                                                                        moment(data.productQuantity.usedUpdatedAt).utc().add(5, 'hours').format("LLL")
                                                                        }
                                                                    </td>
                                                                </tr>

                                                                <tr><th style={{ fontSize: '12px', minWidth: '100px' }} >Faulty</th>
                                                                    <td style={{ minWidth: '35px', maxWidth: '35px' }} contentEditable="true"
                                                                        suppressContentEditableWarning={true}
                                                                        style={{ verticalAlign: ' middle' }}
                                                                        id={"faulty_" + data._id}
                                                                        onBlur={() => this.Update(data._id, "faulty_" + data._id, 'productQuantity.faulty')}>{data.productQuantity.faulty}
                                                                    </td>

                                                                    <td
                                                                        style={{ verticalAlign: ' middle' }}
                                                                    // id={"updatedAt_" + data._id}
                                                                    // onBlur={() => this.Update(data._id, "updatedAt_" + data._id, 'updatedAt')}
                                                                    >{(data.productQuantity.faultyUpdatedAt == '') ?
                                                                        "-"
                                                                        :
                                                                        moment(data.productQuantity.faultyUpdatedAt).utc().add(5, 'hours').format("LLL")

                                                                        }
                                                                    </td>
                                                                </tr>

                                                                <tr><th style={{ fontSize: '12px', minWidth: '100px' }} >Total </th>
                                                                    <td style={{ minWidth: '35px', maxWidth: '35px' }} contentEditable="true"
                                                                        suppressContentEditableWarning={true}
                                                                        style={{ verticalAlign: ' middle' }}
                                                                        id={"total_" + data._id}
                                                                        onBlur={() => this.Update(data._id, "total_" + data._id, 'productQuantity.total')}>{data.productQuantity.total}
                                                                    </td>

                                                                    <td
                                                                        style={{ verticalAlign: ' middle' }}
                                                                    // id={"updatedAt_" + data._id}
                                                                    // onBlur={() => this.Update(data._id, "updatedAt_" + data._id, 'updatedAt')}
                                                                    >{(data.productQuantity.totalUpdatedAt == '') ?
                                                                        "-"
                                                                        :
                                                                        moment(data.productQuantity.totalUpdatedAt).utc().add(5, 'hours').format("LLL")
                                                                        }
                                                                    </td>
                                                                </tr>

                                                                {/* </td> */}
                                                                {/* <td className="p-0"> */}

                                                                {/* <tr>
                                                                    <th style={{ verticalAlign: ' middle' }} className="bg-light ">Last Updated:</th>
                                                                    <td
                                                                        style={{ verticalAlign: ' middle' }}
                                                                    // id={"updatedAt_" + data._id}
                                                                    // onBlur={() => this.Update(data._id, "updatedAt_" + data._id, 'updatedAt')}
                                                                    >{moment(data.updatedAt).utc().add(5, 'hours').format("LLL")}</td>
                                                                </tr> */}
                                                                {/* </td> */}
                                                                {/* </tr> */}
                                                            </table>
                                                        </td>

                                                        <td className="p-0">
                                                            <tbody>
                                                                <tr><th style={{ fontSize: '12px', verticalAlign: ' middle', height: '68px' }}>Sensor Reference: </th>
                                                                    <td
                                                                        style={{ minWidth: '100px', maxWidth: '100px' }}
                                                                        data-id={data._id}
                                                                        data-field="reference"
                                                                    // id={"reference_" + data._id}
                                                                    // onBlur={() => this.Update(data._id, "reference_" + data._id, 'links.reference')}
                                                                    >
                                                                        <marquee duration="3" behavior="">
                                                                            <a href={data.links.reference} target="_blank">{data.links.reference}</a>
                                                                        </marquee>
                                                                        <span className="fas fa-edit float-right linkEdit" style={{ cursor: 'pointer' }}></span>
                                                                    </td>
                                                                </tr>
                                                                <tr><th style={{ fontSize: '12px', verticalAlign: ' middle', height: '68px' }}>Document: </th>
                                                                    <td
                                                                        style={{ minWidth: '100px', maxWidth: '100px' }}
                                                                        data-id={data._id}
                                                                        data-field="document"
                                                                    // id={"document_" + data._id}
                                                                    // onBlur={() => this.Update(data._id, "document_" + data._id, 'links.document')}
                                                                    >
                                                                        <marquee duration="3" behavior="">
                                                                            <a href={data.links.document} target="_blank" >{data.links.document}</a>
                                                                        </marquee>
                                                                        <span className="fas fa-edit float-right linkEdit" style={{ cursor: 'pointer' }}></span>
                                                                    </td>
                                                                </tr>
                                                                <tr><th style={{ fontSize: '12px', verticalAlign: ' middle', height: '57px' }}>Link: </th>
                                                                    <td
                                                                        style={{ minWidth: '100px', maxWidth: '100px' }}
                                                                        data-id={data._id}
                                                                        data-field="link"
                                                                    // id={"link_" + data._id}
                                                                    // onBlur={() => this.Update(data._id, "link_" + data._id, 'links.link')}
                                                                    >
                                                                        <marquee duration="3" behavior="">
                                                                            <a href={data.links.link} target="_blank">{data.links.link}</a>
                                                                        </marquee>
                                                                        <span className="fas fa-edit float-right linkEdit" style={{ cursor: 'pointer' }}></span>
                                                                    </td>
                                                                </tr>

                                                            </tbody>
                                                        </td>


                                                        {/* <td contentEditable="true"
                                                    suppressContentEditableWarning={true}
                                                    style={{ verticalAlign: ' middle' }}
                                                    id={"received_" + data._id}
                                                    onBlur={() => this.Update(data._id, "received_" + data._id, 'received')}
                                                >{data.received}</td> */}

                                                        {/* <td className="p-0">
                                                    <tbody className="p-1">
                                                        <tr><th className="bg-light ">Last Received: </th></tr>
                                                        <tr>
                                                            <td className="p-0"
                                                                style={{ verticalAlign: ' middle' }}
                                                                id={"receivedAt_" + data._id}
                                                                onBlur={() => this.Update(data._id, "receivedAt_" + data._id, 'receivedAt')}
                                                            >
                                                                <input type="date"
                                                                    style={{ width: '55px', border: "1px" }}
                                                                    id={"receivedAt_" + data._id}
                                                                    onChange={() => this.Update(data._id, "receivedAt_" + data._id, 'receivedAt')} defaultValue={data.receivedAt} />
                                                                :
                                                                <input type="date" id={"receivedAt_" + data._id}
                                                                    onChange={() => this.Update(data._id, "receivedAt_" + data._id, 'receivedAt')} value={data.receivedAt} />

                                                            </td>
                                                        </tr>

                                                        <tr><th className="bg-light ">Last Updated:</th></tr>
                                                        <tr>
                                                            <td
                                                                style={{ verticalAlign: ' middle' }}
                                                                id={"updatedAt_" + data._id}
                                                                onBlur={() => this.Update(data._id, "updatedAt_" + data._id, 'updatedAt')}
                                                            >{moment(data.updatedAt).utc().add(5, 'hours').format("LLL")}</td>

                                                        </tr>
                                                    </tbody>
                                                </td> */}


                                                        <td>
                                                            <span>
                                                                {/* <a className="text-info mr-2" onClick={() => this.Update(data._id)} ><i className="fas fa-edit" ></i></a> */}
                                                                <a className="text-danger" ><i className="fas fa-trash-alt"
                                                                    onClick={() => this.delete(data._id)}></i></a>
                                                            </span>
                                                        </td>
                                                    </tr>

                                                )
                                            }
                                        </tbody>}
                                </table>
                            </div>
                            {/* <div>
                                {this.state.pagination.map((page, key) =>
                                (key < (this.state.pagination.length)-1 )?
                                    <button className="btn btn-light" onClick={() => {
                                        let selectedPage = null
                                        selectedPage = {
                                            start: page,
                                            end: this.state.pagination[key + 1],
                                        }
                                        this.setState({ selectedPage })
                                    }}>{key + 1}</button>:null
                                )} 
                            </div> */}
                            <div className="d-flex justify-content-end">
                                <Pagination color='primary' count={this.state.Pages} onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                                    let selectedPage = null
                                    selectedPage = {
                                        start: (value - 1) * this.state.getlimit,
                                        end: (value - 1) * this.state.getlimit + this.state.getlimit,
                                    }
                                    console.log(selectedPage)
                                    this.setState({ selectedPage })
                                }} />
                            </div>

                            {/* <div className="col-12 bg-light p-2" style={{ marginTop: '-50px' }}>
                                <nav className="d-flex justify-content-center">
                                    <ul class="pagination">
                                        <li
                                            class={(this.state.pagination.hasPrevPage) ?
                                                "page-item"
                                                :
                                                "page-item disabled"
                                            }
                                        >
                                            <a class="page-link" onClick={() => this.getDataQuery(this.state.pagination.prevPage, 10)} tabindex="-1">Previous</a>
                                        </li>
                                        { }
                                        <li class="page-item active"><a class="page-link" onClick={() => this.getDataQuery(1, 10)}>1
                                        </a></li>
                                       
                                        <li class="page-item ">
                                            <a class="page-link" onClick={() => this.getDataQuery(this.state.pagination.nextPage, 10)}>2 </a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link" onClick={() => this.getDataQuery(this.state.pagination.nextPage, 10)}>3</a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link" onClick={() => this.getDataQuery(this.state.pagination.nextPage, 10)}>4</a>
                                        </li>
                                        <li class="page-item px-3 disabled">...</li>
                                        <li class="page-item"><a class="page-link" href="#">7</a></li>
                                        <li
                                            class={(this.state.pagination.hasNextPage) ?
                                                "page-item"
                                                :
                                                "page-item disabled"
                                            }
                                        >
                                            <a class="page-link" onClick={() => this.getDataQuery(this.state.pagination.nextPage, 10)}>Next</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div> */}

                        </div>
                        <Footer />
                    </div>

                </div>
        )
        // }
        // else {
        //     return <div className="w-100 d-flex justify-content-center" style={{ height: '100vh' }}>
        //         <img src={Loader} className="d-flex align-self-center" style={{ width: "250px", margin: 'auto' }} />
        //     </div>
        // }

    }
}
dashboard.propsTypes = {
    fetchList: PropsTypes.func.isRequired,
    posts: PropsTypes.array.isRequired

};

const mapStateToProps = (state) => ({
    posts: state.posts.items,
    update: state.posts.update
});




export default connect(mapStateToProps, { fetchList, Update })(dashboard)
