import React, { Component } from 'react'
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import { Button } from 'reactstrap';
import ConfirmPopup from '../popup/confirmPopup2';
import Warning from '../popup/WarningPopup'

import cookies from '../../shared/utilities/cookies'
// import '../../index.css';
import SideNav, { sidenav } from '../sidenav/sidenav'
import axios from 'axios';
import Header from '../header/header'
import Footer from '../footer/footer'
import { Steps } from 'primereact/steps';
import { Toast } from 'primereact/toast';
import { Redirect } from "react-router-dom";
import moment from 'moment';
import { getDate, getTime } from '../../functions/functions'


class Processes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
            orders: '',
            originalOrder: '',
            confirmPopup: false,
            WarningPopup: false,
            redirect: false,
            loader: false,
            user: cookies.getCookie('user'),
        };
    }

    async getORder() {
        if (cookies.getCookie('token')) {  // get token if user logged  
            this.setState({ redirect: false });
        }
        else {
            this.setState({ redirect: true });   //get token if user logged   or redirect  to login
        }
        let url = 'https://ottomatically.com/inventory-server/api/order'
        let token = cookies.getCookie('token');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        axios.get(url).then(response => {
            // let temp = await response.json(

            let orderdata = []
            let originalOrder = []

            response.data.map((data, key) => {
                let selectedstage = []
                // let activeIndex = null
                orderdata.push(data)
                originalOrder.push(data.stages)
                data.stages.map((stage, key2) => {
                    if (stage.status === '1') {
                        this.state.activeIndex = key2
                    }
                    // selectedstage.push(this.items.find(item => item.label === stage.name))
                    selectedstage.push({

                        label: stage.name,
                        // command: (event) => {
                        //     this.toast.show({ severity: 'warn', summary: stage.name, detail: data.description });
                        // }
                    })
                })
                orderdata[key]['index'] = this.state.activeIndex
                orderdata[key]['stages'] = selectedstage
            }
            )
            this.setState({ orders: orderdata, originalOrder })
        }).catch(error => {
            console.log(error)
        })
    }
    componentDidMount() {
        this.getORder()
    }
    reload = () => {
        this.getORder()
    }
    changeIndex = (filterIndex, stages) => {
        console.log("checkOrder 3", filterIndex, stages)
        let url = 'https://ottomatically.com/inventory-server/api/order/' + filterIndex._id + '/update '
        let token = cookies.getCookie('token');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        let body = {
            stages: filterIndex.stages
        }
        this.closePopup()
        axios.put(url, body)


            .then((res) => {
                this.reload()


            }).catch(e => {
                alert(e.response.data.message)
                this.reload()
            })


        // filterIndex.stages = filterStage
    }
    cancelStep = (e, key) => {
        this.state.originalOrder[key].map((or, key2) => {
            if (key2 === e.index) {
                or.status = "0";
            }
            else if (key2 === e.index - 1) {
                or.status = "1";
            }
        })

        this.closePopup()
    }
    closePopup = () => {
        this.setState({ confirmPopup: null })
    }
    closePopup1 = () => {
        this.setState({ WarningPopup: null })
    }
    deleteOrder = (_id) => {
        let url = ('https://ottomatically.com/inventory-server/api/order/' + _id + '/delete')
        axios.delete(url).then(res => {
            console.log(res.data)
            this.reload()
        }).catch(error => {
            console.log(error)
        })
    }
    showConfirmPopup = (e, key) => {
        console.log("checkOrder 1", this.state.originalOrder[key])
        let filterIndex = this.state.orders.find((order, key2) => key === key2)
        let tempstage = filterIndex.stages;
        filterIndex.stages = this.state.originalOrder[key]
        let filterStage = ''
        let showPopup = false
        // let prevIndex = filterIndex.stages.find(stage=>stage.status === )
        filterIndex.stages.map((stage, key2) => {
            if (key2 < e.index && key2 >= 0) {
                stage.status = '2'
                stage.endDate = moment().utc().add(5, 'hours').toISOString()
                stage.completedBy = this.state.user

            }
            else if (key2 === e.index) {
                if (stage.status === "0") {
                    showPopup = true
                    stage.status = '1';

                    stage.startDate = moment().utc().add(5, 'hours').toISOString();

                    stage.createdBy = this.state.user
                }
                else {
                    this.setState({
                        WarningPopup: <Warning heading={"Step Details"} msg={this.state.originalOrder[key].map((stage, key2) => {
                            if (key2 === e.index) {

                                if (stage.name === "Done") {
                                    return (
                                        <div>

                                            Date: {stage.startDate ? getTime(stage.startDate) + " " + getDate(stage.startDate) : "null"} <br />

                                        </div>
                                    )
                                }
                                // else if (stage.name === "Done") {

                                // }
                                else {
                                    return (
                                        // stage.startDate + "</br>" + stage.endDa te 
                                        <div>
                                            Start Date: {stage.startDate ? getTime(stage.startDate) + " " + getDate(stage.startDate) : "null"} <br />
                                            {/* EndDate:    {stage.endDate ? getTime(stage.endDate) + " " + getDate(stage.endDate) : " null"}<br /> */}
                                            CompletedBy: {stage.completedBy}
                                        </div>
                                    )
                                }
                            }
                        })} onNo={() => {
                            filterIndex.stages = tempstage;
                            console.log(filterIndex)
                            this.state.originalOrder[key].map((or, key2) => {
                                // if (key2 === e.index) {
                                //     or.status = "0";
                                // }
                                if (key2 === e.index - 1) {
                                    or.status = "1";
                                }
                            });
                            this.closePopup1()
                        }} />
                    })
                }
            }
            // else {
            //     stage.status = '0'
            // }
            filterStage = filterIndex.stages

        })
        if (showPopup) {
            this.setState({
                confirmPopup: <ConfirmPopup
                    onYes={() => {
                        console.log("checkOrder 2", this.state.originalOrder[key])
                        filterIndex.stages = this.state.originalOrder[key];
                        this.changeIndex(filterIndex, this.state.originalOrder[key])
                    }}
                    onNo={() => {
                        filterIndex.stages = tempstage;
                        this.cancelStep(e, key)
                    }
                    }
                />
            })
        }
    }
    render() {
        if (this.state.redirect == true) {
            return <Redirect to="/" />
        }
        const { orders } = this.state
        return (
            <div className="pt-3 bg-white">
                <SideNav />
                <Header />

                <h2 className="gradient-primary3 p-3 mt-5 dropshadow  text-white d-flex justify-content-between">
                    In Progress</h2>
                <table >
                    <Toast ref={(el) => { this.toast = el }}></Toast>
                    <tr>
                        <th className="col-sm-2">Solution Name</th>
                        <th className="col-sm-2">Order</th>
                        <th className="col-sm-1">Quantity</th>
                        <th className="col">Progress</th>
                        <th className="col-sm-2">Delete</th>
                        {/* <th className="col-sm-3">Status</th> */}
                    </tr>
                    {orders.length > 0 ?
                        orders.map((order, key) => {
                            console.log(order)
                            return (

                                <tr key={key} style={{ backgroundColor: key % 2 === 1 ? "rgb(240, 255 , 240)" : null }}>
                                    <td className="col-sm-2">{order.description}</td>
                                    <td className="col-sm-2">{order.name}</td>
                                    <td className="col-sm-1">{order.quantity}</td>


                                    <td className="col" ><Steps model={order.stages} activeIndex={order.index} onSelect={(e) => { this.showConfirmPopup(e, key) }} readOnly={false} /></td>

                                    <td className="col-sm-2"><Button onClick={(_id) => { this.deleteOrder(order._id) }} color="danger" style={{ display: 'block', marginTop: '10px', borderRadius: '50px', float: 'right' }}>Delete</Button></td>
                                    {/* <td className="col-sm-3"></td> */}
                                </tr>
                            )
                        })
                        : null}
                    {this.state.confirmPopup}
                    {this.state.WarningPopup}
                </table>
                <Footer />
            </div>
        )
    }
}


export default Processes