import { FETCH_POST, NEW_POST, UPDATE_POST, ADD_POST, FETCH_DETAIL, FETCH_COST, FETCH_SOLUTION } from "./types";
import axios from "axios";
import cookies from "../shared/utilities/cookies";
import FormData from 'form-data'


let URL = 'https://ottomatically.com';

export const fetchList = () => async (dispatch) => {
  try {
    console.log("action Fetch");
    let token = cookies.getCookie('token');
    axios.defaults.headers['Authorization'] = 'Bearer ' + token;
    // const interval = setInterval(async() =>  {
    await axios.get(URL + '/inventory-server/api/product'
      // , {
      //   params: {
      //     perpage: 100
      //   }
      // }
    ).
      then(response =>
  dispatch({
    type: FETCH_POST,
    payload: response.data.result,
  })
      );
    // }, 10000);
  } catch (error) {
    console.log("Redux Solution Fetch Error  :", error.response)
  }
  // dispatch({
  //   type: FETCH_POST,
  //   payload: []
  // })
};

export const Login = (credentials) => async (dispatch) => {
  try {
    console.log("action Login");
    let token = cookies.getCookie('token');
    axios.defaults.headers['Authorization'] = 'Bearer ' + token;
    await axios.post(URL + "/inventory-server/api/users/signin", credentials)
      .then((response) =>
        dispatch({
          type: NEW_POST,
          payload: response,
        })
      ).catch((error) =>
        console.log("Catch error", error)
      )
    // console.log("Sign In response",response)
  } catch (err) {
    console.log("Redux Login Error  :", err.response)
  }
};

export const Update = (id, newData, fieldName) => (dispatch) => {
  try {
    console.log("action Update");

    if (fieldName === 'image') {
      console.log("Image file Updating")
      let formData = new FormData();
      formData.append('image', newData)
      let token = cookies.getCookie('token');

      let config = {
        headers: {
          'Authorization': `Bearer ${token}`,
          'content-Type': 'multipart/form-data'
        }
      }
      axios.put(URL + '/inventory-server/api/product/' + id + '/update', formData, config)
        .then((response) =>
          dispatch({
            type: UPDATE_POST,
            payload: response,
          })
        );
    }

    else {
      let token = cookies.getCookie('token');
      axios.defaults.headers['Authorization'] = 'Bearer ' + token;
      console.log("axios", newData)
      axios.put(URL + '/inventory-server/api/product/' + id + '/update', newData)
        .then((response) =>
          dispatch({
            type: UPDATE_POST,
            payload: response,
          })
        );
    }
  } catch (err) {
    console.log("Redux Update Error  :", err.response)
  }
};

export const addProduct = (formData) => (dispatch) => {
  try {
    console.log("action Add");
    let token = cookies.getCookie('token');
    let config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'content-Type': 'multipart/form-data'
      }
    }
    axios.post(URL + '/inventory-server/api/product/create', formData, config)
      .then((response) =>
        dispatch({
          type: ADD_POST,
          payload: response,
        })
      );

  } catch (err) {
    console.log("Redux Add Product Error  :", err.response);
  }
};

export const fetchDetails = (optionId, quantity) => async (dispatch) => {
  try {
    let token = cookies.getCookie('token');
    axios.defaults.headers['Authorization'] = 'Bearer ' + token;
    await axios.get(URL + '/inventory-server/api/solutions/' + optionId + '/manufacture', {
      params: { quantity: quantity }
    }).
      then(response =>
        dispatch({
          type: FETCH_DETAIL,
          payload: response.data,
        })
      )
  } catch (error) {
    console.log("Redux Fetch Details Error  :", error.response)
  }

}

export const fetchCost = (optionId, quantity) => async (dispatch) => {
  try {
    let token = cookies.getCookie('token');
    axios.defaults.headers['Authorization'] = 'Bearer ' + token;
    await axios.get(URL + '/inventory-server/api/solutions/' + optionId + '/cost', {
      params: { quantity: quantity }
    }).
      then(response =>
        dispatch({
          type: FETCH_COST,
          payload: response.data,
        })
      );
  } catch (error) {
    console.log("Redux FetchCost Error  :", error.response)
  }

}

export const fetchSolution = (optionId, forAddBOM) => async (dispatch) => {
  try {
    let token = cookies.getCookie('token');
    axios.defaults.headers['Authorization'] = 'Bearer ' + token;
    await axios.get(URL + '/inventory-server/api/solutions/' + optionId + '/complete').
      then(response =>
        dispatch({
          type: FETCH_SOLUTION,
          payload: response.data, forAddBOM
        })
      );
  } catch (error) {
    console.log("Redux FetchSolution Error  :", error.response)
  }

}