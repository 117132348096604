import React, { Component } from 'react'
import { connect } from 'react-redux';
import SideNav from '../sidenav/sidenav';
import Header from '../header/header';
import Footer from '../footer/footer';
import Stages from './AddStages';
import LoaderGif from "../../assets/img/loader/loader.svg";
import bom from '../Bom/bom'

import { fetchDetails } from '../../actions/postActions';
import $, { data } from 'jquery';
import ReactExport from "react-data-export";
import Pdf from "react-to-pdf";
import { Redirect } from 'react-router-dom';
import { thisExpression } from '@babel/types';

// import ReactHTMLTableToExcel from 'react-html-table-to-excel';
// import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
// import { PDFViewer } from '@react-pdf/renderer';
// import ReactPDF from '@react-pdf/renderer';

// const ref = React.createRef();
// const options = {
//     orientation: 'portrait',
//     unit: 'in',
//     format: [4,2]
// };
var dataSet2 = [];
var dataSet3 = [];
var today = '';


// const styles = StyleSheet.create({
//     page: {
//         flexDirection: 'row',
//         backgroundColor: '#E4E4E4'
//     },
//     section: {
//         margin: 10,
//         padding: 10,
//         flexGrow: 1
//     }
// });

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const ExcelCaption = ReactExport.ExcelFile.ExcelCaption;


export class costTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            costDetails: [],
            cost: null,
            bomName: '',
            // Qty: '',
            detailsArr: [],
            stagesPopup: false
        }
        console.log("this.props.details", this.props.details)
        console.log(this.props)

        // $(document).ready(function() {
        //     $('#table-to-xls').DataTable( {
        //         dom: 'Bfrtip',
        //         buttons: [
        //             'copy', 'csv', 'excel', 'pdf', 'print'
        //         ]
        //     } );
        // } );
    }

    componentWillReceiveProps(nextProps) {

        if (this.props.details !== nextProps.details) {
            this.setState({ costDetails: nextProps.details }, () => console.log("costDetails", this.state.costDetails));
            this.setState({ cost: nextProps.cost }, () => console.log("cost", this.state.cost));


        }
        dataSet2 = [];
        if (nextProps.details.length !== 0) {
            nextProps.details.map(detail => dataSet2.push({
                name: detail.product.name,
                compNo: detail.product.partNumber,
                Required: detail.total,
                InStock: detail.available,
                ToOrder: (detail.available > detail.total) ? 0
                    :
                    detail.total - detail.available

            }))
            console.log("dataset", dataSet2);

            console.log(nextProps)


            dataSet3 = [];
            {
                dataSet2.filter(filter => filter.ToOrder > 0).map(filteredData => dataSet3.push({
                    solutionname: filteredData.bomName,
                    name: filteredData.name,
                    compNo: filteredData.compNo,
                    ToOrder: filteredData.ToOrder
                }))
                console.log("dataset", dataSet3);
            }
            today = new Date();
            var dd = String(today.getDate()).padStart(2, '0')
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();

            today = yyyy + '-' + mm + '-' + dd;
            console.log("Date", today)

            if (this.props.solution !== undefined) {
                this.setState({ bomName: this.props.solution.name }, () => console.log("bomName", this.state.bomName));
                // console.log("bomName", this.state.bomName)
                // this.setState({ Qty: this.props.details[0].solutionQty }, () => console.log("Solution", this.state.Qty))
            }
        }

    }
    // proceed() {

    //     <Redirect to="/processes" />
    // }
    // downPDF = () => {
    //     const input = document.getElementById("table-to-xls");
    //     const pdf = new jsPDF({ unit: "px", format: "letter", userUnit: "px" });
    //     pdf.html(input, { html2canvas: { scale: 0.5 } }).then(() => {
    //         pdf.save("cost.pdf");
    //     });
    // }

    closePopup = () => {
        this.setState({ stagesPopup: null })
    }
    printOrder = () => {
        let printData = "",
            data = dataSet3,

            solutionname = this.state.bomName,
            items = ""
        console.log("data", data)
        // if (data.updated) {
        //     date =
        //         // functions.getTime(
        //         //   data.updated
        //         // ) +
        //         //   " " +
        //         functions.getDate(
        //             data.updated
        //         )
        // }
        data.map((item, key) => {
            items +=
                '<tr>' +
                '<td class="cont">' +
                '<b>' +
                (key + 1) +
                '</b>' +
                '</td>' +
                '<td>' +
                item.name +
                '</td>' +
                '<td>' +
                item.compNo +
                '</td>' +
                '<td>' +
                item.ToOrder +
                '</td>' +
                '</tr>'
        })
        printData =
            '<!DOCTYPE html>' +
            '<html>' +
            '<head>' +
            '<title>' + this.state.bomName + (today) + '</title>' +
            '<style>' +
            'body {' +
            'font-family: Arial, Helvetica, sans-serif;' +
            '}' +
            '.cont{' +
            'background-color: rgb(240,240,240);' +
            'padding: 10px;' +
            '}' +
            '.d-flex{' +
            'display: flex;' +
            '}' +
            '.col-6{' +
            'flex: 50%; ' +
            '}' +
            'table{' +
            'text-align: left;' +
            '}' +
            'tbody tr{' +
            'border-bottom: 1px solid grey;' +
            '}' +
            '</style>' +
            '</head>' +
            '<body>' +
            '<div class="cont">' +
            '<div style="margin-top: 10px">Sulotion Name: <b>' + solutionname + (today) + " Order " + '</b> Sulotion Quantity: <b>' + this.props.details[0].solutionQty + '</b>+</div>' +
            '<div style="margin-top: 10px">' +
            '<table cellpadding="10px">' +
            '<thead class="cont">' +
            '<tr>' +
            '<th>' +
            'S.No.' +
            '</th>' +
            '<th style="width:35%;">' +
            'Component Name' +
            '</th>' +
            '<th style="width:35%;">' +
            'Component Number' +
            '</th>' +
            '<th style="width:30%;">' +
            'To Order' +
            '</th>' +

            '</tr>' +
            '</thead>' +
            '<tbody>' +
            items +
            '</tbody>' +
            '</table>' +
            '</div>' +
            '</body>' +
            '</html>';
        let mywindow = window.open(
            "",
            "PRINT",
            "height=650,width=900,top=100,left=150"
        );
        mywindow.document.write(printData);
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/
        mywindow.print();
        mywindow.close();
    }
    printCompelte = () => {
        let printData = "",
            data = dataSet2,

            solutionname = this.state.bomName,
            items = ""
        console.log("data", data)
        // if (data.updated) {
        //     date =
        //         // functions.getTime(
        //         //   data.updated
        //         // ) +
        //         //   " " +
        //         functions.getDate(
        //             data.updated
        //         )
        // }
        data.map((item, key) => {
            items +=
                '<tr>' +
                '<td class="cont">' +
                '<b>' +
                (key + 1) +
                '</b>' +
                '</td>' +
                '<td>' +
                item.name +
                '</td>' +
                '<td>' +
                item.compNo +
                '</td>' +
                '<td>' +
                item.Required +
                '</td>' +
                '<td>' +
                item.InStock +
                '</td>' +
                '<td>' +
                item.ToOrder +
                '</td>' +
                '</tr>'
        })
        printData =
            '<!DOCTYPE html>' +
            '<html>' +
            '<head>' +
            '<title>' + this.state.bomName + (today) + '</title>' +
            '<style>' +
            'body {' +
            'font-family: Arial, Helvetica, sans-serif;' +
            '}' +
            '.cont{' +
            'background-color: rgb(240,240,240);' +
            'padding: 10px;' +
            '}' +
            '.d-flex{' +
            'display: flex;' +
            '}' +
            '.col-6{' +
            'flex: 50%; ' +
            '}' +
            'table{' +
            'text-align: left;' +
            '}' +
            'tbody tr{' +
            'border-bottom: 1px solid grey;' +
            '}' +
            '</style>' +
            '</head>' +
            '<body>' +
            '<div class="cont">' +
            '<div style="margin-top: 10px">Sulotion Name: <b>' + solutionname + (today) + '</b>' + '</b > Sulotion Quantity: <b>' + this.props.details[0].solutionQty + '</b></div>' +
            '<div style="margin-top: 10px">' +
            '<table cellpadding="10px">' +
            '<thead class="cont">' +
            '<tr>' +
            '<th>' +
            'S.No.' +
            '</th>' +
            '<th style="width:30%;">' +
            'Component Name' +
            '</th>' +
            '<th style="width:30%;">' +
            'Component Number' +
            '</th>' +
            '<th style="width:20%;">' +
            'Required' +
            '</th>' +
            '<th style="width:20%;">' +
            'In Stock' +
            '</th>' +
            '<th style="width:20%;">' +
            'To Order' +
            '</th>' +

            '</tr>' +
            '</thead>' +
            '<tbody>' +
            items +
            '</tbody>' +
            '</table>' +
            '</div>' +
            '</body>' +
            '</html>';
        let mywindow = window.open(
            "",
            "PRINT",
            "height=650,width=900,top=100,left=150"
        );
        mywindow.document.write(printData);
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/
        mywindow.print();
        mywindow.close();
    }
    render() {

        if (this.state.costDetails.length !== 0) {
            return (
                <div className="container" style={{ paddingTop: '60px', minHeight: 'calc(100vh - 70px)' }}>
                    <SideNav />
                    <Header />

                    {/* <Pdf targetRef={ref} filename="code-example.pdf" scale={0.8}  >
                        {({ toPdf }) => <button onClick={toPdf}>Generate Pdf</button>}
                    </Pdf> */}

                    <div class="dropdown">
                        <button className="btn-md btn btn-primary text-center dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ float: 'right', marginBottom: '20px', marginRight: '10px' }}>
                            PDF
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenu2" >
                            <button className="btn-md btn btn-primary text-center dropdown-item" onClick={() => this.printCompelte()} style={{ float: 'right', marginBottom: '20px', marginRight: '10px' }}>Compelete List
                            </button>
                            <button className="btn-md btn btn-primary text-center dropdown-item" onClick={() => this.printOrder()} style={{ float: 'right', marginBottom: '20px', marginRight: '10px' }}>Add To Order List
                            </button>
                        </div>

                    </div>
                    <div class="dropdown">
                        <button className="btn-md btn btn-primary text-center dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ float: 'right', marginBottom: '20px', marginRight: '10px' }}>
                            Excel
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenu2" >
                            <ExcelFile filename={today + '-' + this.state.bomName} element={<button className="btn-md btn btn-primary text-center dropdown-item" style={{ float: 'right', marginBottom: '20px', marginRight: '10px' }}>Compelete List</button>}>
                                <ExcelSheet data={dataSet2} name="Components"  >
                                    <ExcelColumn label="Solution Name" value="solutionname" />
                                    <ExcelColumn label="Component Name" value="name" />
                                    <ExcelColumn label="Comp Number" value="compNo" />
                                    <ExcelColumn label="Required" value="Required" />
                                    <ExcelColumn label="Instock" value="InStock" />
                                    <ExcelColumn label="To Order" value="ToOrder" />
                                </ExcelSheet>
                            </ExcelFile>
                            <br />
                            <ExcelFile filename={today + '-' + this.state.bomName + '-' + "Order Component"} element={<button className="btn-md btn btn-primary text-center dropdown-item" style={{ float: 'right', marginBottom: '20px', marginRight: '10px' }}>Add To Order List</button>}>
                                <ExcelSheet data={dataSet3} name="Components"  >
                                    <ExcelColumn width="205px" label="Component Name" value="name" />
                                    <ExcelColumn label="Comp Number" value="compNo" />
                                    <ExcelColumn label="To Order" value="ToOrder" />
                                </ExcelSheet>
                            </ExcelFile>


                        </div>
                    </div>

                    {/* React lib for creating excel file */}

                    {/* <ReactHTMLTableToExcel  
                        id="test-table-xls-button"
                        className="btn-md btn btn-primary float-right mx-2"
                        table="table-to-xls"
                        filename="tablexls"
                        sheet="tablexls"
                        buttonText="Excel" /> */}

                    <label style={{ float: 'right', lineHeight: '40px', marginRight: '20px' }}><b>Download As:</b></label>

                    {/* <div style={{ width:800,height:5000 }} ref={ref}> */}

                    <h4>Solution Name: {this.state.bomName} <br /> Solution Quantity: {this.props.details[0].solutionQty}</h4>
                    <table id="table-to-xls" className="table table-bordered" >
                        <thead>
                            <tr className="gradient-primary3">
                                <th scope="col" style={{ fontSize: '14px', textAlign: 'center', color: 'white', width: '10px' }}>#</th>
                                <th scope="col" style={{ fontSize: '14px', textAlign: 'center', color: 'white', width: '500px' }}>Product</th>
                                <th scope="col" style={{ fontSize: '14px', textAlign: 'center', color: 'white', width: '500px' }}>Comp No</th>
                                <th scope="col" style={{ fontSize: '14px', textAlign: 'center', color: 'white', width: '50px' }}>Required</th>
                                <th scope="col" style={{ fontSize: '14px', textAlign: 'center', color: 'white', width: '50px' }}>In-Stock</th>
                                <th scope="col" style={{ fontSize: '14px', textAlign: 'center', color: 'white', width: '50px' }}>To Order</th>
                                <th scope="col" style={{ fontSize: '14px', textAlign: 'center', color: 'white', width: '50px' }}>Picture</th>

                            </tr>
                        </thead>
                        <tbody>

                            {

                                this.state.costDetails.map((detail, index) =>
                                    <tr key={detail.product._id}>

                                        <th scope="row">{index + 1}</th>
                                        <td>{detail.product.name}</td>
                                        {detail.product.partNumber.length > 0 ? < td > {detail.product.partNumber}</td> : <td>-------</td>}

                                        <td>{detail.total}</td>
                                        <td>{detail.available}</td>
                                        <td>{(detail.available > detail.total) ? 0
                                            :
                                            detail.total - detail.available
                                        }
                                        </td>
                                        <td>
                                            {detail.product.productImage ?

                                                <img width="50px" height="50px"
                                                    src={'https://ottomatically.com/inventory-server/images/' + detail.product.productImage} />
                                                :
                                                "No Image"
                                            }
                                        </td>

                                    </tr>
                                )
                            }

                        </tbody>
                    </table>
                    {/* </div> */}

                    <div className="row">
                        <div className="col-12">
                            <label className=" float-right" >
                                <b style={{ marginRight: '20px' }}>Total Cost: </b>
                                <label className=" float-right" >
                                    <b style={{ marginRight: '10px' }}>{(this.state.cost ? this.state.cost : 0)}  </b>
                                    <label className=" float-right" >
                                        <b>PKR</b>
                                    </label>
                                </label>

                            </label>
                        </div>

                        <div className="col-12">
                            <button className="btn-md btn btn-primary text-center float-right" onClick={() => this.setState({ stagesPopup: <Stages closePopup={() => this.closePopup()} history={this.props.history} /> })}> Proceed</button>

                        </div>
                    </div>
                    {/* <Stages /> */}
                    {console.log(this.state.stagesPopup)}
                    {this.state.stagesPopup}
                    {/* <PDFViewer>
                        <Document>
                            <Page size="A4" style={styles.page}>
                                <View style={styles.section}>
                                    <Text>Section #1</Text>
                                </View>
                                <View style={styles.section}>
                                    <Text>Section #2</Text>
                                </View>
                            </Page>
                        </Document>
                    </PDFViewer> */}
                    <Footer />
                </div>

            )
        }
        else {

            return (
                <div>
                    {/* {
                        (this.props.details.length === 0) ?
                            <Redirect to="/bom" />
                            :
                            null
                    } */}
                    <img src={LoaderGif} className="w-100 m-1" style={{ position: 'fixed', top: '35%', height: '50%', width: '50%' }} />
                </div>
            )

        }
    }
}

const mapStateToProps = (state) => ({
    details: state.posts.detail,
    cost: state.posts.cost,
    solution: state.posts.solution
})



export default connect(mapStateToProps, { fetchDetails })(costTable)
