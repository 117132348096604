import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, Button } from 'reactstrap';
import { MultiSelect } from 'primereact/multiselect';
import { fetchDetails } from '../../actions/postActions';
import axios from 'axios';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import moment from 'moment';
class Stages extends Component {
    constructor(props) {
        super(props);

        this.state = {
            order: '',
            BOMname: '',
            name: '',
            stages: [],
            quantity: '',
            solutionId: '',
            redirect: false
            // Stages: []
        };
        console.log(this.props)

        // this.Stages = [
        //     "Manufacturing",
        //     "Development",
        //     "Testing",
        //     "Installation",
        //     "Done"
        // ]
    }



    // generateType = (device, status) => {
    //     return '' + this.props.currentHub.type + '_' + device.type + '_' + status
    // }
    keyHandleer = (event) => {
        console.log("this", this.props)
        this.setState({
            [event.target.name]: (event.target.name) === 'stages' ? event.target.value : event.target.value

        }
        )
    }
    addstage() {
        this.setState({ stages: [...this.state.stages, ''] })
    }
    handleChange(e, index) {
        this.state.stages[index] = e.target.value

        this.setState({ stages: this.state.stages })
    }
    handleremove(index) {
        this.state.stages.splice(index, 1)

        this.setState({ stages: this.state.stages })
    }


    handleRequest = () => {
        let stages = [{ name: "pending", status: 1, startDate: moment().utc().add(5, 'hours') }]
        // if (stages.le > 0) {
        this.state.stages.map((stage) => {
            stages.push({ name: stage, status: 0 })
        })
        stages = [...stages, { name: "Done", status: 0 }]
        // }

        if (this.state.order === '') {

            alert("Plz Enter Order Name")
        }
        else {
            // console.log(this.props)
            // console.log(this.state.order)
            // console.log("stages", stages)
            // console.log("Solutionname", this.props.solution.name)
            // console.log("sulotion Id", this.props.solution._id)
            // console.log('sultion qty', this.props.details[0].solutionQty)
            let url = 'https://ottomatically.com/inventory-server/api/order/create'
            let body = {
                solutionId: this.props.solution._id,
                description: this.props.solution.name,
                name: this.state.order,
                quantity: this.props.details[0].solutionQty,
                stages: stages,
            }
            console.log(body)
            // fetch(url, {
            //     method: 'POST',
            //     body: JSON.stringify(
            //         {
            //             solutionId: this.props.solution._id,
            //             name: this.state.order,
            //             quantity: this.props.details[0].solutionQty,
            //             stages: stages,
            //         }).then((result) => {
            //             result.json().then((res) => {
            //                 console.warn(res)
            //             })
            //         })


            // })
            axios.post(url, body)

                .then((res) => {
                    console.log(res.data)
                    this.props.closePopup()
                    this.props.history.push('/processes')
                    // console.log(this.props)

                    // props.reload()


                }).catch(e => {
                    alert(e.response.data.message)
                    this.props.closePopup()
                })
        }
    }


    render() {
        return (
            <div style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', background: 'rgba(255, 255, 255, 0.5)', display: 'flex', zIndex: '1', backdropFilter: 'blur(5px)' }}>
                <div className="rounded-1" style={{ background: 'rgb(255, 255, 255)', margin: 'auto', boxShadow: '0px 10px 20px 0px rgba(0,0,0,0.2)', padding: '10px', }}>
                    <div className="card" style={{ border: '0px' }}>
                        <a className="close align-self-end rounded-circle" onClick={() => this.props.closePopup()} style={{ textAlign: 'center', color: 'red', lineHeight: '18px', width: '22px', paddingBottom: '4px' }}>
                            &times;
                        </a>
                        <div className="clearfix px-5 pb-3 card-body level-comp">
                            <h1 className="mt-2" style={{ textAlign: 'center' }}>ORDER AND STAGES </h1>
                            <h6 className="pl-3 mt-2" >Enter Your Order Name and Stages</h6>
                            {/* <input style={{ width: '250px', border: '0px', padding: '10px 20px', borderRadius: '50px', backgroundColor: 'rgb(240,240,240)' }} type="password" value={this.state.password} onChange={this.handlePassword} /> */}
                            <input className="mt-2" style={{ width: '100%', border: '0px', padding: '10px 20px', borderRadius: '50px', backgroundColor: 'rgb(240,240,240)' }} type="text" placeholder="Order Name"
                                value={this.state.order} name={"order"} onChange={(event) => this.keyHandleer(event)}
                            />
                            {/* <input className="mt-2" style={{ width: '100%', border: '0px', padding: '10px 20px', borderRadius: '50px', backgroundColor: 'rgb(240,240,240)' }} type="text" placeholder="Add Stage"
                                value={this.state.addstage} name={"Add Stage"} onChange={(event) => this.keyHandle(event)}
                            
                            /> */}

                            <div style={{ maxHeight: "calc(90vh - 300px)", overflow: "auto" }}>
                                {this.state.stages.map((stage, index) => {
                                    return (
                                        <div key={index} >
                                            <input className="mt-2" style={{ width: '85%', border: '0px', padding: '10px 20px', borderRadius: '50px', backgroundColor: 'rgb(240,240,240)' }} placeholder="Stages Name"
                                                value={stage} list={"stages" + index} name={"stages"} onChange={(e) => this.handleChange(e, index)}
                                            />
                                            <datalist id={"stages" + index}>
                                                <option value="Manufacturing" />
                                                <option value="Development" />
                                                <option value="Testing" />
                                                <option value="Installation" />
                                                {/* <option value="Done" /> */}
                                            </datalist>
                                            <Button color="danger" style={{ display: 'block', marginTop: '10px', borderRadius: '50%', float: 'right' }} onClick={() => this.handleremove(index)}><i class="fas fa-minus-circle"></i></Button>
                                        </div>

                                    )
                                })}
                                <hr />
                            </div>
                            <Button color="success"
                                style={{ display: 'block', justifyContent: "center", marginTop: '10px', borderRadius: '50px', float: 'right' }} onClick={(e) => { this.addstage(e) }}>Add Stage</Button>
                            <br />
                            <br />

                            {/* <MultiSelect className="mt-2" style={{ width: '100%', border: '0px', padding: '10px 20px', borderRadius: '50px', backgroundColor: 'rgb(240,240,240)' }}
                                type="text" name={"stages"}
                                value={this.state.stages} placeholder="Stagesname" options={this.Stages} width="150px" onChange={(event) => this.keyHandleer(event)} display="chip"
                            /> */}
                            {/* <input className="mt-2" style={{ width: '100%', border: '0px', padding: '10px 20px', borderRadius: '50px', backgroundColor: 'rgb(240,240,240)' }} type="text" placeholder="Stages"
                                value={this.state.stages} name={"stages"} onChange={(event) => this.keyHandleer(event)}
                            /> */}

                            <div>
                                {/* <Button color="secondary" onClick={() => { this.handleVerification(1) }} style={{ display: 'block', marginTop: '10px', borderRadius: '50px', float: 'right' }}>Submit</Button> */}
                                <Button color="success"
                                    style={{ display: 'block', marginTop: '10px', borderRadius: '50px', float: 'right' }} onClick={() => this.handleRequest()}>
                                    Submit</Button>
                            </div>
                            {/* <Button color="secondary" onClick={() => { this.handleVerification(1) }} style={{ display: 'block', marginTop: '10px', borderRadius: '50px', float: 'right' }}>Submit</Button> */}

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    details: state.posts.detail,
    cost: state.posts.cost,
    solution: state.posts.solution
})



export default connect(mapStateToProps, { fetchDetails })(Stages)