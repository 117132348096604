import { Dashboard } from '@material-ui/icons';
import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export class sidenav extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    // componentDidMount(){
    //     console.log("current",this.props.match.params.action)
    // }
    /* Set the width of the side navigation to 250px */
    openNav = () => {
        if (document.getElementById("mySidenav").offsetWidth == 250) {
            document.getElementById("mySidenav").style.width = "0";
            // document.getElementById("main").style.marginLeft = "0";
        } else {
            document.getElementById("mySidenav").style.width = "250px";
            // document.getElementById("main").style.marginLeft = "250px";
        }
    }

    /* Set the width of the side navigation to 0 */
    closeNav = () => {
        document.getElementById("mySidenav").style.width = "0";
        document.getElementById("main").style.marginLeft = "0";
        // document.body.style.backgroundColor = "white";
    }
    render() {
        return (
            <div>
                <div id="mySidenav" className="sidenav gradient-primary3" >
                    <ul className="row" >
                        <li className="col-12">
                            {/* <a className="closebtn" onClick={this.closeNav}>&times;</a> */}
                            <Link title="Dashboard" to={"/dashboard"} className="text-white navbtn text-left" ><i className="fas fa-tachometer-alt "></i > Dashboard</Link>
                            <Link title="Add Inventory" to="/addinventory" className="text-left"><i className="fas fa-dolly-flatbed"></i> Add Inventory</Link>
                            <Link title="Select BOM" to="/bom" className="text-left"><i className="fas fa-archive"></i> Select BOM</Link>
                            <Link title="Add BOM" to="/addbom" className="text-left"><i className="fas fa-boxes"></i> Add BOM</Link>
                            <Link title="Procees" to='/processes' className="text-left"><i class="fas fa-hourglass-half"></i> Processes</Link>
                        </li>
                    </ul>
                </div>
                <div id="main">
                </div>

                <button onClick={this.openNav} style={{ backgroundColor: 'white', border: 0, width: '50px', height: '50px', position: 'fixed', top: 0, left: 0, zIndex: 2 }}><i className="fas fa-bars text-center"></i></button>

            </div>
        )
    }
}

export default sidenav
