import React, { lazy } from 'react';
import { Provider } from 'react-redux'
import store from './store';
import Login from './components/login/login'
import Dashboard from './components/dashboard/dashboard';
import AddInventory from './components/addInventory/addInventory';
import AddBom from './components/Bom/addBom';
import EditBom from './components/Bom/editBom';
import Bom from './components/Bom/bom';
import CostTable from './components/costTable/costTable';
import Processes from './components/Proceed/proceed';
// import LazyLoad from 'react-lazyload';




import { Redirect, BrowserRouter, HashRouter, Route, Switch } from 'react-router-dom';
function App() {

  return (
    // <BrowserRouter>
    //   <ThemeProvider theme={theme}>
    //     <Switch>
    //       <Route path="/" exact component={SignInPage} />
    //       <Route path="/register" exact component={RegisterPage} />
    //       <Route path="/createprofile" exact component={CreateProfilePage} />
    //       <Route path="/postproduct" exact component={PostProductPage} />
    //       <Route path="/reviewproduct" exact component={ReviewProductPage} />
    //       <Route path="/product" exact component={ProductPage} />
    //     </Switch>
    //   </ThemeProvider>
    // </BrowserRouter>

    <Provider store={store}>
      <HashRouter>
        <Switch>
          <Route path='/' exact component={Login} />
          <Route path='/dashboard' component={Dashboard} />
          <Route path='/addinventory' component={AddInventory} />
          <Route path='/addbom' component={AddBom} />
          <Route path='/bom' component={Bom} />
          <Route path='/edit/bom' component={EditBom} />
          <Route path='/costtable' component={CostTable} />
          <Route path='/processes' component={Processes} />

        </Switch>
      </HashRouter>
    </Provider>
  );
}

export default App;
