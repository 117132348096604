import { FETCH_POST, NEW_POST, UPDATE_POST, ADD_POST ,FETCH_DETAIL,FETCH_COST,FETCH_SOLUTION } from "../actions/types";

const intialState = {
  items: [],
  item: {},
  update: [],
  add: [],
  detail:[],
  cost:null,
  solution:[],
  forAddBOM:null
};

export default function (state = intialState, action) {
  switch (action.type) {
    case FETCH_POST:
      console.log(FETCH_POST)
      console.log(action);
      return {
        ...state,
        items: action.payload,
      };
    case NEW_POST:
      console.log("LOGIN_POST");
      console.log(action);
      return {
        ...state,
        item: action.payload,
      };

    case UPDATE_POST:
      console.log("UPDATE_POST");
      console.log(action);
      return {
        ...state,
        update: action.payload,
      };

    case ADD_POST:
      console.log("ADD_POST");
      console.log(action);
      return {
        ...state,
        add: action.payload,
      };

      case FETCH_DETAIL:
      console.log("FETCH_DETAIL")
      console.log(action);
      return {
        ...state,
        detail: action.payload,
      };

      case FETCH_COST:
        console.log("FETCH_COST")
        console.log(action);
        return {
          ...state,
          cost: action.payload,
        };

        case FETCH_SOLUTION:
        console.log("FETCH_SOLUTION")
        console.log(action);
        return {
          ...state,
          solution: action.payload,
          forAddBOM:action.forAddBOM
        };

    default:
      return state;
  }
}
