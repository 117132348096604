import React, { Component } from 'react';
import { Card, CardBody, Button } from 'reactstrap';
import $ from 'jquery';
import cookies from '../../shared/utilities/cookies'
import axios from 'axios';
import { waitForDomChange } from '@testing-library/react';

class ConfirmPassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            password: '',
            wrong: false
        };
    }
    handlePassword(event) {
        this.setState({
            password: event.target.value,

        });
    }

    userVerification = async (password) => {
        try {
            let token = cookies.getCookie('token');
            axios.defaults.headers['Authorization'] = 'Bearer ' + token;
            let response = await axios.post('https://ottomatically.com/inventory-server/api/users/confirmation', { password: password });
            console.log("valid response", response)

            if (response.data === 'valid') {
                this.setState({
                    wrong: false,
                });
                this.props.popup(null,true)
                // let token = cookies.getCookie('token');
                // axios.defaults.headers['Authorization'] = 'Bearer ' + token;
                // let res = await axios.delete('https://ottomatically.com/inventory-server/api/product/' + this.props.DeleteId + '/delete');
                // console.log("deleted", res.data);
                // if (res.status === 401) {
                //     alert("Authorization denied, only Admins");
                // }
                // else {
                //     alert("Deleted Successfully");y

                // }
            }
            else {
                this.setState({
                    wrong: true,
                });
            }
            // return response.data;
        } catch (err) {
            // err.response.data.error = true;
            // return err.response.data;
        }
    }
    render() {
        return (
            <div style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', background: 'rgba(255, 255, 255, 0.5)', display: 'flex', zIndex: '1020', backdropFilter: 'blur(5px)' }}>
                <div onClick={() => this.props.popup(null,false)} style={{position:'absolute',top:'0',left:'0',bottom:0,right:0}}></div>
                <div className="rounded-1" style={{ background: 'rgb(255, 255, 255)', margin: 'auto', boxShadow: '0px 10px 20px 0px rgba(0,0,0,0.2)', padding: '10px', }}>
                    <div className="card" style={{ border: '0px' }}>
                        <a className="close align-self-end rounded-circle" onClick={() => this.props.popup(null,false)} style={{ textAlign: 'center', color: 'red', lineHeight: '18px', width: '22px', paddingBottom: '4px' }}>
                            &times;
                        </a>
                        <div className="clearfix px-5 pb-3 card-body level-comp">
                            <h1 style={{ textAlign: 'center', margin: 0 }}>Confirm</h1>
                            <div className=" text-muted text-uppercase text-center font-weight-bold font-sm mdi mdi-account" style={{ marginBottom: '28px' }}> </div>
                            <h6 className="pl-3" style={{ marginBottom: '5px' }}>Enter Your Password</h6>
                            {/* <input style={{ width: '250px', border: '0px', padding: '10px 20px', borderRadius: '50px', backgroundColor: 'rgb(240,240,240)' }} type="password" value={this.state.password} onChange={this.handlePassword} /> */}
                            <input style={{ width: '250px', border: '0px', padding: '10px 20px', borderRadius: '50px', backgroundColor: 'rgb(240,240,240)' }} type="password" placeholder="Password"
                                onChange={(event) => this.handlePassword(event)} />
                            {(this.state.wrong) ?
                                <div className="font-xs pl-3" style={{ color: 'red' }} >Wrong Password</div>
                                :
                                null
                            }
                            <div>
                                {/* <Button color="secondary" onClick={() => { this.handleVerification(1) }} style={{ display: 'block', marginTop: '10px', borderRadius: '50px', float: 'right' }}>Submit</Button> */}
                                <Button onClick={() => this.userVerification(this.state.password)} color="success rounded-1" style={{ display: 'block', marginTop: '10px', float: 'right' }}>Submit</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default ConfirmPassword;