import React, { Component } from 'react'
import axios from 'axios';
import cookies from '../../shared/utilities/cookies'
import SideNav from '../sidenav/sidenav'
import Header from '../header/header'
import Footer from '../footer/footer'
import { Redirect } from "react-router-dom";

import $, { data } from 'jquery';
import AddBom from './addBom';
import { fetchDetails, fetchCost, fetchSolution } from '../../actions/postActions'
import { connect } from 'react-redux';
import LoaderGif from "../../assets/img/loader/loader.svg";

let forAddBOM = false;
let optionId = ''
let URL = 'https://ottomatically.com';


export class bom extends Component {
    constructor(props) {
        super(props)
        this.state = {
            solutions: [],
            components: [],
            blocks: [],
            updateSolutionData: [],
            updateSOl: false,
            redirect_NewSol: false,
            cost: '',
            details: [],
            showDetailTable: false,
            Quantity: null,
            loader: false,
            redirect: false
        }
    }

    handleSelect = async () => {
        optionId = $("#solutionSelect").val();
        console.log("option Value", optionId);

        // this.setState({ loader: true });

        if (optionId !== null) {
            if (optionId !== "Select") {
                try {
                    let token = cookies.getCookie('token');
                    axios.defaults.headers['Authorization'] = 'Bearer ' + token;
                    let res2 = await axios.get(URL + '/inventory-server/api/blocks/' + optionId + '/solution')
                    console.log("response blocks", res2)
                    this.setState({ blocks: res2.data })
                    console.log("blocks", this.state.blocks)
                } catch (error) {
                    console.log("Bom Block Error  :", error.response)
                }
            }

            try {
                let token = cookies.getCookie('token');
                axios.defaults.headers['Authorization'] = 'Bearer ' + token;
                let res1 = await axios.get(URL + '/inventory-server/api/components/' + optionId + '/solution')
                console.log("response components", res1)
                this.setState({ components: res1.data })

                console.log("components", this.state.components);
            } catch (error) {
                console.log("Bom Component Error  :", error.response)
            }



            // forAddBOM = false;
            // let res = await this.props.fetchSolution(optionId, forAddBOM); // calling fetchSolution action for cost table


            // this.setState({ loader: false });
        }
    }
    async componentWillMount() {
        if (cookies.getCookie('token')) {  // get token if user logged  
            this.setState({ redirect: false });
        }
        else {
            this.setState({ redirect: true });   //get token if user logged   or redirect  to login
        }

        this.setState({ loader: true });

        optionId = ''; //for again showing add button

        try {
            let token = cookies.getCookie('token');
            axios.defaults.headers['Authorization'] = 'Bearer ' + token;
            let res = await axios.get(URL + '/inventory-server/api/solutions')
            console.log("response Solution", res)
            this.setState({ solutions: res.data })
            console.log("solutions", this.state.solutions)

            if (this.state.solutions.length !== 0) {
                this.setState({ loader: false });
            }
        } catch (error) {
            console.log("Bom Solution Error  :", error.response)
        }

    }

    updateSolution = async () => {
        // let res3 = await axios.get(URL + '/inventory-server/api/solutions/' + optionId + '/complete');
        // console.log("response 3", res3);
        // this.setState({ updateSolutionData: res3.data });
        // console.log("complete solution", this.state.updateSolutionData)

        //for fetching solution for Update        // moved to redux 
        forAddBOM = true;
        let res = await this.props.fetchSolution(optionId, forAddBOM);

        if (this.props.solution !== null) {
            console.log("reducer response", this.props.solution);
            this.setState({ updateSOl: true });
        }
    }
    addSolution = () => {
        this.setState({ redirect_NewSol: true })
    }
    calculateCost = async () => {
        // calling fetchSolution action for cost table
        console.log("this.state.Quantity", this.state.Quantity)

        console.log("option Id", optionId)
        // let response = await axios.get(URL + '/inventory-server/api/solutions/' + optionId + '/cost', {
        //     params: { quantity: this.state.Quantity }
        // });
        // console.log("COst response", response);
        // this.setState({ cost: response.data });
        if (this.state.Quantity === '') {
            alert("Please enter the quantity")
            this.setState({ showDetailTable: false });
        }
        else if (this.state.Quantity < 0) {
            alert("Please enter the correct quantity")
            this.setState({ showDetailTable: false });
        }
        else if (this.state.Quantity === null) {
            alert("Please enter the quantity")
            this.setState({ showDetailTable: false });
        }
        else if (this.state.Quantity == 0) {
            alert("Please enter the correct quantity")
            this.setState({ showDetailTable: false });
        }
        else {
            if (this.state.Quantity !== 0 && this.state.Quantity !== null && this.state.Quantity !== '') {
                forAddBOM = true;
                let res = await this.props.fetchSolution(optionId, forAddBOM);
                this.props.fetchCost(optionId, this.state.Quantity);  //calling fetchCost action
                this.setState({ showDetailTable: true });
            }
        }

        // let response2 = await axios.get(URL + '/inventory-server/api/solutions/' + optionId + '/manufacture', {
        //     params: { quantity: this.state.Quantity }
        // });
        // console.log("details response", response2);
        // this.setState({ details: response2.data });

        this.props.fetchDetails(optionId, this.state.Quantity); //calling fetchDetails action
    }

    getQuantity = (event) => {
        this.setState({ Quantity: event.target.value }, () => console.log("Quantity", this.state.Quantity));

    }

    render() {
        if (this.state.redirect == true) {
            return <Redirect to="/" />
        }

        if (this.state.redirect_NewSol) {
            return <Redirect to="/addbom" />;
        }
        if (this.state.updateSOl === true) {
            return <Redirect to="/edit/bom" />;

        }
        if (this.state.showDetailTable === true) {
            return <Redirect to="/costtable" />;
        }

        return (
            this.state.loader ?
                <img src={LoaderGif} className="w-100 m-1" style={{ position: 'fixed', top: '35%', height: '50%', width: '50%' }} />
                :
                <div>
                    <SideNav />
                    <Header />

                    {/* {(this.state.updateSOl && !this.state.showDetailTable) ?
                        <AddBom Bom_solution={this.state.updateSolutionData} />
                        : */}
                    <>
                        {/* { !this.state.showDetailTable && */}

                        <div className="container" style={{ paddingTop: '60px', minHeight: 'calc(100vh - 70px)' }}>
                            <h1 className="text-left">BOM</h1>

                            <select className="form-control form-control-lg mb-3" id="solutionSelect"
                                onChange={() => this.handleSelect()}
                            >
                                <option>Select</option>
                                {this.state.solutions.map(sol =>
                                    <option value={sol._id} key={sol._id}>{sol.name}</option>

                                )}
                            </select>
                            {(optionId !== '') ?
                                <div>
                                    <div className="accordion">
                                        <div className="card">
                                            <div className="card-header justify-content-between d-flex mb-3" data-toggle="collapse" data-target="#Board_GTB6s9TBU" aria-controls="Board_GTB6s9TBU">
                                                <h5 className="mb-0 text-info">Standard BOM</h5>
                                                {/* <span>
                                <a className="btn text-info"><i className="fas fa-edit"></i></a>
                                <a className="btn text-danger"><i className="fas fa-trash-alt"></i></a>
                                </span> */}
                                            </div>

                                            <div id="Board_GTB6s9TBU" className="collapse">
                                                <div className="card-body">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" >#</th>
                                                                <th scope="col">Name</th>
                                                                <th scope="col">Quantity</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.components.map((comp, index) =>
                                                                <tr>
                                                                    <th scope="row">{index + 1}  </th>
                                                                    <td>{comp.name}</td>
                                                                    <td>{comp.quantity}</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion">
                                        <div className="card">
                                            <div className="card-header justify-content-between d-flex" data-toggle="collapse" data-target="#Controller_3_wP6p2Io" aria-controls="Controller_3_wP6p2Io">
                                                <h5 className="mb-0 text-info">Customization</h5>
                                                {/* <span>
                                    <a className="btn text-info"><i className="fas fa-edit"></i></a>
                                    <a className="btn text-danger"><i className="fas fa-trash-alt"></i></a>
                                
                                         </span> */}
                                                <div className="col-12 mb-10">
                                                    {(optionId === '') ?
                                                        <button className="btn-md btn btn-primary text-center float-right" style={{ marginRight: '120px' }} onClick={this.addSolution}>Add Solution</button>
                                                        :
                                                        <button className="btn-md btn btn-primary text-center float-right" style={{ marginRight: '120px' }} onClick={this.updateSolution}>Update Solution</button>

                                                    }


                                                </div>
                                            </div>

                                            <div id="Controller_3_wP6p2Io" className="collapse">
                                                <div className="card-body ">
                                                    <div className="row d-flex">
                                                        {
                                                            this.state.blocks.map((block, index) =>
                                                                <div className="accordion">
                                                                    {/* {console.log("Block id", block._id)} */}

                                                                    <div key={block.name} className="card mr-5 justify-content-between d-flex mb-2">
                                                                        <div className=" card-header " data-toggle="collapse" data-target={"#bbb" + block._id} aria-controls={"bbb" + block._id}>
                                                                            <h5 className="mb-0 text-info">{block.name}</h5>
                                                                            {/* <span>
                                                                     <a className="btn text-info"><i className="fas fa-edit"></i></a>
                                                                        <a className="btn text-danger"><i className="fas fa-trash-alt"></i></a>
                                                                     </span> */}
                                                                        </div>

                                                                        <div id={"bbb" + block._id} className="collapse">
                                                                            <div className="card-body">
                                                                                <table className="table">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th scope="col">#</th>
                                                                                            <th scope="col">Name</th>
                                                                                            <th scope="col">Quantity</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>

                                                                                        {block.components.map((comp, compIndex) =>
                                                                                            <tr>
                                                                                                <th scope="row">{compIndex + 1}  </th>
                                                                                                <td>{comp.name}</td>
                                                                                                <td>{comp.quantity}</td>
                                                                                            </tr>
                                                                                        )}


                                                                                    </tbody>
                                                                                </table>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-12 ">

                                        <div>
                                            <input type="number" className="text-center float-right" placeholder="Enter Quantity"
                                                min="1"
                                                style={{ position: 'absolute', right: '0px', marginTop: '20px' }}
                                                onChange={this.getQuantity} >

                                            </input>

                                            <button className="btn-md btn btn-primary text-center float-right"
                                                style={{
                                                    position: 'absolute', right: '0px',
                                                    top: '50px', marginTop: '20px'
                                                }}
                                                onClick={this.calculateCost}>Finalize</button>


                                        </div>




                                    </div>

                                </div>

                                : null}
                        </div>
                        {/* } */}
                        {/* {(this.state.showDetailTable) ?
                        
                            // <div className="col-8" style={{ position: "fixed", top: 20, left: 20, bottom: 20, right: 20 }}>
                            //     <CostTable costDetails={this.state.details} TotalCost={this.state.cost} />
                            // </div>

                            :
                            null
                        } */}
                        {/* {this.state.showDetailTable &&
                            <div className="container " style={{ paddingTop: '100px' }}>
                                <table class="table table-bordered" >
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ fontSize: '14px', textAlign: 'center', color: 'white', backgroundColor: '#31A2AC', width: '10px' }}>#</th>
                                            <th scope="col" style={{ fontSize: '14px', textAlign: 'center', color: 'white', backgroundColor: '#31A2AC', width: '500px' }}>Product</th>
                                            <th scope="col" style={{ fontSize: '14px', textAlign: 'center', color: 'white', backgroundColor: '#31A2AC', width: '50px' }}>Required</th>
                                            <th scope="col" style={{ fontSize: '14px', textAlign: 'center', color: 'white', backgroundColor: '#31A2AC', width: '50px' }}>In-Stock</th>
                                            <th scope="col" style={{ fontSize: '14px', textAlign: 'center', color: 'white', backgroundColor: '#31A2AC', width: '50px' }}>To Order</th>
                                            <th scope="col" style={{ fontSize: '14px', textAlign: 'center', color: 'white', backgroundColor: '#31A2AC' }}>Picture</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.details.map((detail, index) =>
                                            <tr>

                                                <th scope="row">{index + 1}</th>
                                                <td>{detail.product.name}</td>
                                                <td>{detail.total}</td>
                                                <td>{detail.available}</td>
                                                <td>{(detail.available > detail.total) ? 0
                                                    :
                                                    detail.total - detail.available
                                                }
                                                </td>
                                                <td>
                                                    <img width="50px "
                                                        src={'https://ottomatically.com/inventory-server/images/' + detail.product.productImage} />
                                                </td>

                                            </tr>
                                        )}


                                    </tbody>
                                </table>
                                <div className="col-12">
                                    <label className=" float-right" >
                                        <b style={{ marginRight: '20px' }}>Total Cost: </b>
                                        <label className=" float-right" >
                                            <b style={{ marginRight: '10px' }}>{this.state.cost}  </b>
                                            <label className=" float-right" >
                                                <b>PKR</b>
                                            </label>
                                        </label>

                                    </label>

                                    <button className="btn-md btn btn-primary text-center float-right "
                                        style={{
                                            position: 'absolute', right: '0px',
                                            top: '50px'
                                        }} >Proceed</button>

                                </div>

                            </div>
                        } */}

                    </>
                    {/* } */}

                    <Footer />

                </div>
        )

    }
}

const mapStateToProps = (state) => ({
    details: state.posts.detail,
    cost: state.posts.cost,
    solution: state.posts.solution
})

export default connect(mapStateToProps, { fetchDetails, fetchCost, fetchSolution })(bom)
