import { isMoment } from 'moment'
import React, { Component } from 'react'
import {
    Badge,
    Button,
    ButtonDropdown,
    ButtonGroup,
    ButtonToolbar,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardTitle,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Progress,
    Row,
    Table,
    Fade,
    Collapse,
    Alert
} from "reactstrap";
import { Form, FormGroup, Label, Input, FormText } from "reactstrap";
import cookies from '../../shared/utilities/cookies'
import $, { type } from 'jquery';
import axios from 'axios';
import SideNav from '../sidenav/sidenav'
import Header from '../header/header'
import Footer from '../footer/footer'
import { Redirect } from "react-router-dom";
import header from '../header/header';
import LoaderGif from "../../assets/img/loader/loader.svg";
import { fetchSolution } from '../../actions/postActions'

import { connect } from 'react-redux';

let URL = 'https://ottomatically.com';
let name, desc, select = ''
let quantity = 0;
let compSelect = ''
let block_Id_Temp = [];
let comp_Id_Temp = [];

let deleteIndex = '';
let BlockIndex = '';

let Sol_Name = '';
let Sol_Desc = '';
let Sol_Slect = '';
let product_Id = '';

let forAddBOM = false;
let forBack = false;




export class addBom extends Component {
    constructor(props) {
        super(props)
        this.state = {
            blockCount: [

            ],
            componentCount: [

            ],
            solutionId: '',
            blockId_Arr: [],
            componentId_Arr: '',
            productLists: [],
            solutionTypeList: [],
            blockTypeList: [],
            blockTEst: [],
            componentsTEst: [],

            Solution: [],
            SolutionName: '',
            SolutionDes: '',
            SolutionType: '',
            redirect: false,
            loader: false,
            forUpdateBOM: true
        }
    }
    componentWillMount() {
        if (cookies.getCookie('token')) {  // get token if user logged  in
            this.setState({ redirect: false });
        }
        else {
            this.setState({ redirect: true });   //get token if user logged in  or redirect  to login
        }
    }
    async componentDidMount() {        //fetching all the details from API's needed to add BOM
        try {
            let token = cookies.getCookie('token');
            axios.defaults.headers['Authorization'] = 'Bearer ' + token;
            let response = await axios.get(URL + '/inventory-server/api/product');
            this.setState({ productLists: response.data.result });
            console.log("productLists", this.state.productLists);
        } catch (error) {
            console.log("Product List Error  :", error.response)
        }

        try {
            let token = cookies.getCookie('token');
            axios.defaults.headers['Authorization'] = 'Bearer ' + token;
            let response1 = await axios.get(URL + '/inventory-server/api/solutions/distinct');
            // console.log("response1", response1.data)
            this.setState({ solutionTypeList: response1.data });
        } catch (error) {
            console.log("SolutionType List Error  :", error.response)

        }

        try {
            let token = cookies.getCookie('token');
            axios.defaults.headers['Authorization'] = 'Bearer ' + token;
            let response2 = await axios.get(URL + '/inventory-server/api/blocks/distinct');
            // console.log("response2", response2.data);
            this.setState({ blockTypeList: response2.data });
        } catch (error) {
            console.log("BlockType List Error  :", error.response)
        }




        // console.log("this.props.forAddBOM", this.props.forAddBOM)
        // console.log("forUpdateBOM", this.state.forUpdateBOM)
        // if (this.props.forAddBOM === true && this.state.forUpdateBOM === true) {
        //     if (this.props.solution !== undefined) {
        //         this.setState({ SolutionName: this.props.solution.name })
        //         this.setState({ SolutionDes: this.props.solution.description })
        //         this.setState({ SolutionType: this.props.solution.type })
        //         this.setState({ blockCount: this.props.solution.blocks })
        //         console.log("Solution for Update", this.props.solution)
        //     }
        // }

    }



    solutionHandle = (e) => {

        if (e.target.name === 'SolutionType') {     //for removing spaces from solution type
            this.setState({ [e.target.name]: e.target.value.replace(/\s/g, '') });
            console.log(`${e.target.name} :`, e.target.value.replace(/\s/g, ''));

        }
        else {
            this.setState({ [e.target.name]: e.target.value })
        }
    }

    valueHandle = async (index) => {
        // let blockCount = [...this.state.blockCount];
        // blockCount[index]["components"] = undefined;
        name = $(`#block_name${index}`).val();
        // console.log("name", name)

        BlockIndex = index;
        console.log("BlockIndex", BlockIndex);

        desc = $(`#block_desc${index}`).val();

        // select = $(`#block_select${index}`).val().replace(/\s/g, '');
        // console.log("Block select :", select);

        this.updateBlock(index, 'forName');


    }
    blockSelectHandle = async (index) => {
        let blockCount = [...this.state.blockCount];
        blockCount[index]["components"] = undefined;

        select = $(`#block_select${index}`).val().replace(/\s/g, '');
        console.log("Block select :", select);
        this.updateBlock(index, 'forSelect');


        if (select !== '') {
            let token = cookies.getCookie('token');
            axios.defaults.headers['Authorization'] = 'Bearer ' + token;
            let res = await axios.get("https://ottomatically.com/inventory-server/api/blocks/types/" + select + "/components");
            console.log("Block Type response", res.data);
            console.log("blockCount[index]components", blockCount[index].components);
            if (blockCount[index]["components"] === undefined) {
                // let tempArr =''
                // tempArr.push(res.data)
                blockCount[index]["components"] = res.data;
                console.log("block", this.state.blockCount);
                this.updateBlock(index, 'forSelect');
            }
            else {
                blockCount[index]["components"].push(res.data);
            }
        }

    }
    descriptionHandle = (index) => {
        desc = $(`#block_desc${index}`).val();
        // console.log("desc", desc)
    }
    selectHandle = (Inputid, index, Block_index) => {
        // select = $(`#block_select${index}`).val();
        // // console.log("select", select)
        // compSelect = $(`#comp_select${index}`).val();
        // // console.log("compSelect", compSelect)
        BlockIndex = Block_index;
        console.log("Inputid", Inputid)
        let changed = Inputid.toString();

        compSelect = $(`#${changed}`).val();
        console.log("Component Select", compSelect)

        let Comp_Name = compSelect.split('_')[0];
        console.log("Component Name", Comp_Name)


        let product_Id = compSelect.split('_')[1];
        console.log("Component Prod Id", product_Id)


        console.log("Component Index", index)
        let blockCount = [...this.state.blockCount];
        if (product_Id !== undefined || product_Id !== '') {
            blockCount[BlockIndex].components[index].name = Comp_Name;
            blockCount[BlockIndex].components[index].productId = product_Id;

            this.setState({
                blockCount
            })
        }
        else {
            // alert(`${Comp_Name}: Product ID is undefined`);
            console.log(`${Comp_Name}: Product ID is undefined`);
        }


        // this.setState({
        //     blockCount
        // })
        // this.updateComponent(index);


    }
    quantityHandle = (Inputid, index, Block_index) => {
        BlockIndex = Block_index;
        let changed = Inputid.toString();

        quantity = $(`#${changed}`).val();
        console.log("quantity", quantity)

        let blockCount = [...this.state.blockCount];
        if (blockCount[BlockIndex].components[index].productId !== undefined || blockCount[BlockIndex].components[index].productId !== '') {
            blockCount[BlockIndex].components[index].quantity = quantity;
            // blockCount[BlockIndex].components[index].productId = product_Id;
            this.setState({
                blockCount
            })
        }
        else {
            // alert( 'Product ID is undefined');
            console.log(` Product ID is undefined`);
        }
        // blockCount[BlockIndex].components[index].quantity = quantity;
        // // blockCount[BlockIndex].components[index].productId = product_Id;

        // this.setState({
        //     blockCount
        // })

        // this.updateComponent(index);

    }


    addingblock = () => {
        // console.log("block adding ")
        let data = {
            name: "",
            description: "",
            type: '',
        }
        let tempArr = [...this.state.blockCount];
        tempArr.push(data);
        this.setState({ blockCount: tempArr })

    }
    updateBlock = (index, handleType) => {
        let object = {
            name: name,
            description: desc,
            type: select,
        }
        if (handleType === 'forSelect') {
            let temp = [...this.state.blockCount];
            temp[index].type = object.type;
            this.setState(
                {
                    blockCount: temp
                }
            )
        }
        else {
            let temp = [...this.state.blockCount];
            temp[index].name = object.name;
            temp[index].description = object.description;
            this.setState(
                {
                    blockCount: temp
                }
            )
        }

    }
    deleteBlock = async (index) => {
        console.log("BLock Delete Index", index)

        let temArr = [...this.state.blockCount];
        console.log("block before delete", this.state.blockCount);

        temArr.splice(index, 1);
        // this.setState({ blockCount: temArr })
        this.state.blockCount = temArr;
        console.log("block after delete", this.state.blockCount);
        this.forceUpdate();

    };


    addComponent = (index) => {
        if (this.state.blockCount[index].name !== '' || this.state.blockCount[index].description !== '' || this.state.blockCount[index].type !== '') {
            let data = {

                name: "",
                quantity: 0,
                productId: ""
            }
            console.log("this.state.blockCount.components", this.state.blockCount)

            let blockCount = [...this.state.blockCount];
            console.log("block index of component Before", blockCount[index]["components"])
            if (blockCount[index]["components"] === undefined) {
                let tempArr = []
                tempArr.push(data)
                blockCount[index]["components"] = tempArr;
            }

            else {
                blockCount[index]["components"].push(data);
            }
            // this.state.blockCount.components
            // this.setState({ componentCount: tempArr });
            console.log("block index of component After", blockCount)
            this.setState({ blockCount })
            // this.state.blockCount[index]["components"] = tempArr;
        }
        else {
            alert("Please provide block details");
        }

    }

    // updateComponent = (index) => {  // not using this update funtion on component 
    //     let object = {
    //         name: compSelect,
    //         quantity: quantity,
    //         productId: product_Id
    //     }


    //     let blockCount = [...this.state.blockCount];
    //     console.log(" Block index", BlockIndex)
    //     console.log(" component index", index)
    //     blockCount[BlockIndex].components[index] = object;
    //     console.log("blockCount", blockCount)
    //     this.setState({
    //         blockCount
    //     })

    //     // temp[index].components = object;
    //     // this.state.blockCount[BlockIndex].components = object;

    //     // this.state.blockCount[index].components = object; //for testing
    //     // this.setState(
    //     //     {
    //     //         componentCount: temp
    //     //     }
    //     // )

    //     // this.state.blockCount[BlockIndex].components = temp;
    // }
    deleteComponent = async (index, blockIndex) => {
        console.log("Component Delete Index", index)
        console.log("block Index", blockIndex)

        let blockCount = [...this.state.blockCount];
        console.log("blockCOunt", this.state.blockCount);
        blockCount[blockIndex].components.splice(index, 1);
        console.log("block COunt After ", this.state.blockCount)

        // this.setState({ blockCount })

        this.state.blockCount[blockIndex] = blockCount[blockIndex];

        // this.setState({ blockCount })

        this.forceUpdate();

    }
    validation = () => {

        let valid = true;
        let blockLength = $(".accordionBlock>.card>.collapse").length;
        for (var i = 0; i < blockLength; i++) {
            if ($("#block_name" + i).val() === "" || $("#block_select" + i).val() === "" || $("#block_desc" + i).val() === "") {
                valid = false;
                console.log(blockLength, "check length", $("#block_name" + i).val(), $("#block_select" + i).val(), $("#block_desc" + i).val())
            }
            let compLength = $(".accordion>.card>.collapse>.card-body>table>tbody>tr").length;
            for (var j = 0; j < compLength; j++) {
                if ($("#" + i + "abc" + j).val() === "" || $("#" + i + "xyz" + j).val() === "") {
                    valid = false;
                    console.log(blockLength, compLength, "check length", valid, $("#" + i + "abc" + j).val(), $("#" + i + "xyz" + j).val())
                }
            }
            console.log(blockLength, compLength, "check length", valid, $("#" + i + "abc" + j).val(), $("#" + i + "xyz" + j).val())
        }
        return valid
    }

    createSolution = async () => {

        if (this.state.SolutionName === '' || this.state.SolutionDes === '' || this.state.SolutionType === '' || this.state.blockCount.length === 0) {
            alert("Please provide solution details")
        }

        if (this.state.SolutionName !== '' && this.state.SolutionDes !== '' && this.state.SolutionType !== ''
            && this.state.blockCount.length !== 0) {
            // this.setState({ loader: true });
            let data =
            {
                name: this.state.SolutionName,
                description: this.state.SolutionDes,
                type: this.state.SolutionType,

                blocks: this.state.blockCount
            }
            let valid = this.validation()
            console.log("solutionData", data);
            if (valid === true) {
                try {
                    if (data !== null && data.length !== 0) {
                        let token = cookies.getCookie('token');
                        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
                        let response = await axios.post(URL + "/inventory-server/api/solutions/createcomplete ", data)
                        if (response !== null) {
                            console.log("SolutionResponse", response)
                            this.setState({ loader: false });

                            alert("Solution Added")
                            this.setState({ redirect: true });
                        }
                    }
                } catch (error) {
                    alert("Solution Add Error  :", error)
                    this.setState({ redirect: true });
                }
            }
            else {
                alert("plz filled all input feilds")
            }
        }
    }


    updateSolution = async () => {
        if (this.state.SolutionName === '' || this.state.SolutionDes === '' || this.state.SolutionType === '' || this.state.blockCount.length === 0) {
            alert("Please provide solution details")
        }

        if (this.state.SolutionName !== '' && this.state.SolutionDes !== '' && this.state.SolutionType !== ''
            && this.state.blockCount.length !== 0) {
            this.setState({ loader: true });
            let data =
            {
                name: this.state.SolutionName,
                description: this.state.SolutionDes,
                type: this.state.SolutionType,

                blocks: this.state.blockCount
            }
            console.log("Update Solution", data);

            if (this.props.solution !== undefined) {
                try {
                    if (data !== null && data.length !== 0) {
                        let token = cookies.getCookie('token');
                        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
                        let response = await axios.put(URL + "/inventory-server/api/solutions/" + this.props.solution._id + "/update ", data)
                        if (response !== null) {
                            console.log("Solution Update Response", response)
                            this.setState({ loader: false });

                            alert("Solution Updated");
                            this.setState({ redirect: true });
                        }
                    }
                } catch (error) {
                    console.log("Solution Update Error  :", error.response)
                }
            }
        }
    }

    // deleteSolution = async () => { // removed delete button of solution this is old logic user can add or update solution
    //     if (this.state.solutionId !== '') {

    //         let token = cookies.getCookie('token');
    //         axios.defaults.headers['Authorization'] = 'Bearer ' + token;
    //         let response = await axios.delete(URL + "/inventory-server/api/solutions/" + this.state.solutionId + "/delete")
    //         if (response !== null) {
    //             console.log("solutionsDelete", response)
    //             alert("solutionsDeleted")
    //         }
    //     }
    // }

    createBlock = async () => {
        // let data = {
        //     name: name,
        //     description: desc,
        //     type: select,
        //     solutionId: this.state.solutionId

        // }
        // // console.log("block Data", data)


        // if (this.state.solutionId !== '') {

        //     let token = cookies.getCookie('token');
        //     axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        //     let response = await axios.post(URL + "/inventory-server/api/blocks/create", data)
        //     if (response !== null) {
        //         // console.log("BlockResponse", response)
        //         // this.setState({ blockId_Arr: response.data._id })

        //         block_Id_Temp.push(response.data._id);
        //         this.setState({ blockId_Arr: block_Id_Temp });
        //         // console.log("blockId_Arr array ", this.state.blockId_Arr)

        //         alert("Block Added")
        //     }
        // }
    }

    createComponent = async () => {
        // let data = {
        //     name: compSelect,
        //     quantity: quantity,
        //     solutionId: this.state.solutionId,
        //     blockId_Arr: this.state.blockId_Arr,
        //     productId: 1001
        // }
        // // console.log("Component saved")
        // // console.log("Component Data", data)

        // if (this.state.blockId_Arr !== '') {

        //     let token = cookies.getCookie('token');
        //     axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        //     let response = await axios.post(URL + "/inventory-server/api/components/create", data)
        //     if (response !== null) {
        //         // console.log("ComponentResponse", response)
        //         this.setState({ componentId_Arr: response.data._id })
        //         alert("Component Added")

        //     }
        // }
    }

    async componentWillUnmount() {
        // this.setState({ forUpdateBOM: false }, () => console.log("forUpdateBOM in didMount", this.state.forUpdateBOM));
        //  if (this.props.solution) {
        forAddBOM = false;
        let res = await this.props.fetchSolution('this.props.solution._id', forAddBOM); // for not showing update solution again after user update solution
        // }
    }


    render() {
        if (this.state.redirect == true) {
            return <Redirect to="/bom" />;
        }
        return (
            this.state.loader ?
                <img src={LoaderGif} className="w-100 m-1" style={{ position: 'fixed', top: '35%', height: '50%', width: '50%' }} />
                :
                <div>
                    <SideNav />
                    <Header />

                    <div className="container " style={{ paddingTop: '60px', minHeight: 'calc(100vh - 70px)' }}>
                        {/* <button onClick={() => this.redFun()}>BACK</button> */}

                        <div className="accordion ">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex" data-toggle="collapse" data-target="#Board_GTB6s9TBU" aria-controls="Board_GTB6s9TBU">
                                    <h5 className="mb-0 text-info">Solution</h5>
                                    <span>
                                        <a className="btn bg-success text-white mr-1" onClick={this.createSolution}><i className="far fa-check-circle"></i> Create</a>
                                        {/* {(this.props.solution !== null && this.props.forAddBOM) ?
                                            <>
                                                <a className="btn bg-success text-white mr-1" onClick={this.updateSolution}><i className="far fa-check-circle"></i> Update</a>
                                                <a className="btn bg-success text-white mr-1" onClick={this.createSolution}><i className="far fa-check-circle"></i> Create</a>
                                            </>
                                            :
                                            <a className="btn bg-success text-white mr-1" onClick={this.createSolution}><i className="far fa-check-circle"></i> Create</a>
                                        } */}

                                        {/* <a className="btn bg-danger text-white" onClick={this.deleteSolution}><i className="far fa-times-circle"></i> Delete</a> */}
                                    </span>
                                </div>

                                <div id="Board_GTB6s9TBU" className="collapse">
                                    <div className="card-body">
                                        <div className="row m-0">
                                            <div className="form-group col-6">
                                                <label htmlFor="name">Name</label>
                                                <input name="SolutionName" type="text" value={this.state.SolutionName} className="form-control" id="Solutionname" placeholder="Type Name" onChange={this.solutionHandle} />
                                            </div>
                                            <div className="form-group col-6">
                                                <label htmlFor="selectType">Type</label>
                                                {/* <select name="SolutionType" className="form-control" id="SolutionselectType"
                                                value={this.state.SolutionType}
                                                onChange={this.solutionHandle}>
                                                <option>Select Type</option>

                                                {this.state.solutionTypeList.map(solutionlist =>
                                                    <option>{solutionlist}</option>
                                                )}

                                            </select> */}

                                                <input type="text" className="form-control" list="SolutionselectType" name="SolutionType" value={this.state.SolutionType} onChange={this.solutionHandle} autoComplete="off" />
                                                <datalist id="SolutionselectType">
                                                    {this.state.solutionTypeList.map(solutionlist =>
                                                        <option>{solutionlist}</option>
                                                    )}

                                                </datalist>
                                            </div>
                                            <div className="form-group col-12">
                                                <label htmlFor="description">Description</label>
                                                <input name="SolutionDes" type="text" value={this.state.SolutionDes} className="form-control" id="Solutiondescription" placeholder="Write Description" onChange={this.solutionHandle} />
                                            </div>
                                        </div>
                                        <span className=" d-flex justify-content-end pr-3">
                                            <a className="addblock btn bg-success text-white" onClick={this.addingblock} >
                                                <i className="fa fa-plus-circle"></i> Add Block</a>
                                        </span>

                                        {
                                            (this.state.blockCount.length !== 0) ?
                                                (this.state.blockCount.map((block, index) =>

                                                    <div className="accordionBlock p-3">
                                                        <div className="card">
                                                            <div className="card-header justify-content-between d-flex" data-toggle="collapse" data-target={"#aaa" + index} aria-controls={"aaa" + index}>
                                                                {(block.name) ?
                                                                    <h5 id={"forComponent" + index} className="mb-0 text-info">{block.name}</h5>
                                                                    :
                                                                    <h5 id={"forComponent" + index} className="mb-0 text-info">Block #{index + 1}</h5>
                                                                }

                                                                <span >
                                                                    {/* <a className="btn bg-success text-white mr-1"
                                                                    onClick={this.createBlock}><i className="far fa-check-circle" ></i> Save</a> */}

                                                                    <a className="btn bg-danger text-white"
                                                                        onClick={() => this.deleteBlock(index)}><i className="far fa-times-circle"></i> Delete</a>
                                                                </span>
                                                            </div>

                                                            <div id={"aaa" + index} className="collapse">
                                                                <div className="card-body">
                                                                    <div key={index} className="row m-0">
                                                                        <div className="form-group col-6">
                                                                            <label htmlFor="name">Name</label>
                                                                            <input id={"block_name" + index} value={block.name} type="text" className="form-control" placeholder="Type Name" onChange={() => this.valueHandle(index)} />
                                                                        </div>
                                                                        <div className="form-group col-6">
                                                                            <label htmlFor="selectType">Type</label>
                                                                            {/* <select id={"block_select" + index} className="form-control"
                                                                            value={block.type}
                                                                            onChange={() => this.valueHandle(index)} >
                                                                            <option>Select Type</option>

                                                                            {this.state.blockTypeList.map(blocklist =>
                                                                                <option>{blocklist}</option>
                                                                            )}
                                                                        </select> */}

                                                                            <input type="text" className="form-control" id={"block_select" + index} list={"block_select_Child" + index} value={block.type} autoComplete="off"
                                                                                onChange={() => this.blockSelectHandle(index)} />

                                                                            <datalist id={"block_select_Child" + index} >
                                                                                {this.state.blockTypeList.map(blocklist =>
                                                                                    <option>{blocklist}</option>
                                                                                )}

                                                                            </datalist>
                                                                        </div>

                                                                        <div className="form-group col-12">
                                                                            <label htmlFor="description">Description</label>
                                                                            <input id={"block_desc" + index} value={block.description} type="text" className="form-control" placeholder="Write Description"
                                                                                onChange={() => this.valueHandle(index)} />
                                                                        </div>
                                                                    </div>
                                                                    <span className=" d-flex justify-content-end pr-3">
                                                                        <a className="btn bg-success text-white mr-1" onClick={() => this.addComponent(index)}><i className="fa fa-plus-circle"></i> Add Component</a>
                                                                    </span>

                                                                    {
                                                                        (block.components !== undefined) ?
                                                                            // (block.components.map((item, index) =>

                                                                            <div className="accordion p-3">
                                                                                <div className="card">
                                                                                    <div className="card-header justify-content-between d-flex" data-toggle="collapse"
                                                                                        data-target={"#bbb" + index} aria-controls={"bbb" + index}>
                                                                                        <h5 className="mb-0 text-info">Components</h5>

                                                                                    </div>

                                                                                    <div id={"bbb" + index} className="collapse">
                                                                                        <div className="card-body">
                                                                                            <table className="table">

                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th scope="col">#</th>
                                                                                                        <th scope="col">Name</th>
                                                                                                        <th scope="col">Quantity</th>
                                                                                                        <th scope="col">Action</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {block.components.map((comp, compIndex) =>
                                                                                                        <tr>
                                                                                                            <th scope="row">{compIndex + 1}  </th>
                                                                                                            <td>
                                                                                                                <div key={compIndex} className="form-group ">
                                                                                                                    {/* {console.log("BBB", comp)} */}

                                                                                                                    <input type="text" className="form-control"
                                                                                                                        id={`${index}abc${compIndex}`}
                                                                                                                        list={"component_select_Child" + index}
                                                                                                                        // selected={comp.name}
                                                                                                                        value={comp.name}
                                                                                                                        onChange={() => this.selectHandle(`${index}abc${compIndex}`, compIndex, index)} autoComplete="off"
                                                                                                                    />

                                                                                                                    <datalist
                                                                                                                        id={"component_select_Child" + index} >
                                                                                                                        {this.state.productLists.map(product =>
                                                                                                                            <option
                                                                                                                                selected={product._id === comp.productId ? true : false}
                                                                                                                                value={product.name + ", " + product.partNumber + ", " + product.location
                                                                                                                                    + "_" + product._id
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {product.name + ", " + product.partNumber + ", " + product.location
                                                                                                                                    //  + ", " + product.available
                                                                                                                                }
                                                                                                                            </option>
                                                                                                                        )}

                                                                                                                    </datalist>
                                                                                                                    {/* <select className="form-control"
                                                                                                                    id={`${index}abc${compIndex}`}
                                                                                                                    // selected={comp.name}
                                                                                                                    onChange={() => this.selectHandle(`${index}abc${compIndex}`, compIndex, index)}>
                                                                                                                    <option >Select Component</option>
                                                                                                                    {this.state.productLists.map(product =>
                                                                                                                        <option
                                                                                                                            selected={product._id === comp.productId ? true : false}
                                                                                                                            value={product.name + ", " + product.partNumber + ", " + product.location
                                                                                                                                + "_" + product._id
                                                                                                                            }
                                                                                                                        >
                                                                                                                            {product.name + ", " + product.partNumber + ", " + product.location
                                                                                                                                //  + ", " + product.available
                                                                                                                            }</option>
                                                                                                                    )}
                                                                                                                </select> */}
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <div key={compIndex} className="form-group ">
                                                                                                                    <input
                                                                                                                        id={`${index}xyz${compIndex}`}
                                                                                                                        min='1'
                                                                                                                        value={comp.quantity}
                                                                                                                        type="number" className="form-control" placeholder="Quantity"
                                                                                                                        onChange={() => this.quantityHandle(`${index}xyz${compIndex}`, compIndex, index)} />
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <div className="justify-content-between d-flex">
                                                                                                                    {/* <span> */}
                                                                                                                    {/* <a className="btn bg-light rounded-circle text-success mr-1" onClick={this.createComponent}
                                                                                                                ><i className="far fa-check-circle" ></i>
                                                                                                                </a> */}

                                                                                                                    <a className="btn bg-light rounded-circle text-danger"
                                                                                                                        onClick={() => this.deleteComponent(compIndex, index)}
                                                                                                                    ><i className="far fa-times-circle"></i></a>
                                                                                                                    {/* </span> */}
                                                                                                                </div>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    )}
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            // ))
                                                                            :
                                                                            <Alert color="info">
                                                                                <b>No Component Set</b>
                                                                            </Alert>
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                                :

                                                <Alert color="info">
                                                    <b>No Block Set</b>
                                                </Alert>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>

        )
    }
}
const mapStateToProps = (state) => ({
    details: state.posts.detail,
    cost: state.posts.cost,
    solution: state.posts.solution,
    forAddBOM: state.posts.forAddBOM
})

export default connect(mapStateToProps, { fetchSolution })(addBom)
