import React, { Component } from 'react'
import { connect } from "react-redux";
import cookies from '../../shared/utilities/cookies'
import { addProduct } from '../../actions/postActions'
import axios from 'axios'
import Header from '../header/header'
import SideNav from '../sidenav/sidenav'
import Footer from '../footer/footer'
import LoaderGif from "../../assets/img/loader/loader.svg";
import $ from "jquery";
import FormData from 'form-data'

let URL='https://ottomatically.com/inventory-server/api/product/create';

let ComponentName = null;
let compNumber = null;
let Price = null;
let Available = null;
let Image = null;
let LastUpdatedDate = null;
let ReceivedQuantity = null;
let LastReceivedDate = null;
let Total = null;
let Updated = null;
let Previous = null;
let used = null;
let Faulty = null;
let Sensorrefrence = null;
let Document = null;
let Link = null;
let Location = null;

export class addInventory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            buttonDisable: false,
            imagePath: '',
            imgSrc: '',
            loader: false,
            imagePreview: ''
        }
    }
    // getValues = () => {
    //     ComponentName = $("#ComponentName").val();
    //     compNumber = $("#compNumber").val();
    //     Price = $("#Price").val();
    //     Available = $("#Available").val();
    //     Image = this.state.imagePath;
    //     ReceivedQuantity = $("#ReceivedQuantity").val();
    //     LastReceivedDate = $("#LastReceivedDate").val();
    //     Total = $("#Total").val();
    //     Updated = $("#Updated").val();
    //     Previous = $("#Previous").val();
    //     used = $("#used").val();
    //     Faulty = $("#Faulty").val();
    //     Sensorrefrence = $("#Sensorrefrence").val();
    //     Document = $("#Document").val();
    //     Link = $("#Link").val();
    //     Location = $("#Location").val();

    //     // if (ComponentName == '' || compNumber == '' || Price == '' || Available == '' || Image == '' || ReceivedQuantity == '' || LastReceivedDate == '' || Total == '' || Updated == '' || Previous == '' || used == '' || Faulty == ''
    //     //     || Sensorrefrence == '' || Document == '' || Link == '' || Location == '') {
    //     //     this.setState({ buttonDisable: true })
    //     // }
    //     // else {
    //     //     this.setState({ buttonDisable: false })
    //     // }
    // }

    // componentWillMount() {
    //     let time = setInterval(this.getValues, 2000)
    // }

    readURL = (input) => {
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
                $('#imagePreview').css('background-image', 'url(' + e.target.result + ')');
                $('#imagePreview').hide();
                $('#imagePreview').fadeIn(650);
            }
            reader.readAsDataURL(input.files[0]);
        }
    }

    fileChange = (event) => {
        this.readURL(event.target);
        console.log("Image Path", event.target.value);
        this.setState({ imagePreview: event.target.value });
        this.state.imagePath = [];
        this.setState({ imagePath: event.target.files[0] }, () => console.log("image FIle", this.state.imagePath));

    }


    addInventory = async () => {
        this.setState({ loader: true });

        ComponentName = $("#ComponentName").val();
        compNumber = $("#compNumber").val();
        Price = $("#Price").val();
        Available = $("#Available").val();
        Image = this.state.imagePath;
        ReceivedQuantity = $("#ReceivedQuantity").val();
        // LastReceivedDate = $("#LastReceivedDate").val(); // remove LastReceivedDate  from inventory
        Total = $("#Total").val();
        // Updated = $("#Updated").val(); // remove updated quantity from inventory
        Previous = $("#Previous").val();
        used = $("#used").val();
        Faulty = $("#Faulty").val();
        Sensorrefrence = $("#Sensorrefrence").val();
        Document = $("#Document").val();
        Link = $("#Link").val();
        Location = $("#Location").val();

        let formData = new FormData();
        formData.append('image', Image)
        formData.append('name', ComponentName)
        formData.append('partNumber', compNumber)
        formData.append('price', Price)
        formData.append('available', Available)
        formData.append('received', ReceivedQuantity)
        // formData.append('receivedAt', LastReceivedDate)
        formData.append('total', Total)
        // formData.append('updated', Updated)
        formData.append('previous', Previous)
        formData.append('used', used)
        formData.append('faulty', Faulty)
        formData.append('reference', Sensorrefrence)
        formData.append('document', Document)
        formData.append('link', Link)
        formData.append('location', Location)

        try {

            let token = cookies.getCookie('token');
            let config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'content-Type': 'multipart/form-data'
                }
            }
            if (ComponentName !== '' && Price !== '' && Available !== '' && ReceivedQuantity !== '' && Total !== '' && Previous !== '' && used !== '' && Faulty !== '' && Sensorrefrence !== '' && Document !== '' && Link !== '' && Location !== '' && Image !== '') {

                let Response = await axios.post(URL, formData, config)
                console.log("created", Response)

                if (Response.status === 200) {
                    alert("Added Succefully");
                    this.setState({ loader: false });
                }
            }
            else {
                if (ComponentName !== '' && Price !== '' && Available !== '' && ReceivedQuantity !== '' && Total !== '' && Previous !== '' && used !== '' && Faulty !== '' && Sensorrefrence !== '' && Document !== '' && Link !== '' && Location !== '' && Image === '') {
                    alert("Please Choose Image ")
                }
                else if (ComponentName === '' && Price === '' && Available === '' && ReceivedQuantity === '' && Total === '' && Previous === '' && used === '' && Faulty === '' && Sensorrefrence === '' && Document === '' && Link === '' && Location === '' && Image !== '') {
                    alert("Please fill the required field ")
                }
                else {
                    alert("Please fill the required field ")
                }

                this.setState({ loader: false });

            }

        } catch (error) {
            console.log("Add Inventory Error  :", error.response)

        }
    }
    render() {
        return (
            this.state.loader ?
                <img src={LoaderGif} className="w-100 m-1" style={{ position: 'fixed', top: '35%', height: '50%', width: '50%' }} />
                :
                <div className="d-flex">
                    <SideNav />
                    <div className="w-100">
                        <Header />
                        <div className="container " style={{ minHeight: 'calc(100% - 30px)', marginTop: '100px' }}>
                            <div className="bg-white dropshadow rounded p-4">
                                <h2 className="text-center my-4">Add Inventory</h2>
                                <form>
                                    <div className="row">

                                        <div className="col-12 col-md-6 mt-3">
                                            <label htmlFor="ComponentName">Component Name</label>
                                            <input id="ComponentName" type="text" className="form-control" required placeholder="Component Name" />
                                        </div>
                                        <div className="col-12 col-md-6 mt-3">
                                            <label htmlFor="compNumber">Component Number</label>
                                            <input id="compNumber" type="text" className="form-control" placeholder="Component Number" />
                                        </div>
                                        <div className="col-12 col-md-6 mt-3">
                                            <label htmlFor="Price">Price</label>
                                            <input id="Price" type="number" className="form-control" required placeholder="Price" />
                                        </div>
                                        <div className="col-12 col-md-6 mt-3">
                                            <label htmlFor="Location">Location</label>
                                            <input id="Location" type="text" className="form-control" required placeholder="Location" />
                                        </div>
                                        <div className="col-12 col-md-6 mt-3">
                                            <label htmlFor="Available">Available</label>
                                            <input id="Available" type="number" className="form-control" required placeholder="Available" />
                                        </div>
                                        {/* <div className="col-12 col-md-6 mt-2">
                                    <label htmlFor="LastUpdatedDate">Last Updated Date</label>
                                    <input id="LastUpdatedDate" type="date" className="form-control" required placeholder="Last Updated Date" />
                                </div> */}
                                        <div className="col-12 col-md-6 mt-3">
                                            <label htmlFor="ReceivedQuantity">Received Quantity</label>
                                            <input id="ReceivedQuantity" type="number" className="form-control" required placeholder="Received Quantity" />
                                        </div>
                                        {/* <div className="col-12 col-md-6 mt-3">
                                            <label htmlFor="LastReceivedDate">Last Received Date</label>
                                            <input id="LastReceivedDate" type="date" className="form-control" />
                                        </div> */}



                                        {/* <div className="col-12 col-md-6 mt-3">
                                            <label htmlFor="Updated">Updated</label>
                                            <input id="Updated" type="number" className="form-control" required placeholder="Updated" />
                                        </div> */}
                                        <div className="col-12 col-md-6 mt-3">
                                            <label htmlFor="Previous">Issued Internally</label>
                                            <input id="Previous" type="number" className="form-control" required placeholder="Issued Internally" />
                                        </div>

                                        <div className="col-12 col-md-6 mt-3">
                                            <label htmlFor="used"> Used</label>
                                            <input id="used" type="number" className="form-control" required placeholder="Used" />
                                        </div>
                                        <div className="col-12 col-md-6 mt-3">
                                            <label htmlFor="Faulty">Faulty</label>
                                            <input id="Faulty" type="number" className="form-control" required placeholder="Faulty" />
                                        </div>
                                        <div className="col-12 col-md-6 mt-3">
                                            <label htmlFor="Total">Total</label>
                                            <input id="Total" type="number" className="form-control" required placeholder="Total" />
                                        </div>

                                        <div className="col-12 col-md-6 mt-3">
                                            <label htmlFor="Links">Sensor Reference</label>
                                            <input id="Sensorrefrence" type="text" className="form-control" required placeholder="Sensor Reference" />
                                        </div>
                                        <div className="col-12 col-md-6 mt-3">
                                            <label htmlFor="Links">Document</label>
                                            <input id="Document" type="text" className="form-control" required placeholder="Document" />
                                        </div>
                                        <div className="col-12 col-md-6 mt-3">
                                            <label htmlFor="Links">Link</label>
                                            <input id="Link" type="text" className="form-control" required placeholder="Link" />
                                        </div>

                                        

                                        <div className="col-12 col-md-6 mt-3">
                                            <label className="w-100">&nbsp;</label>
                                            <div className="d-flex">
                                                <div>
                                                    <label className="text-center btn btn-primary" for="imagefile">
                                                        Upload Image
                                                        <input type="file" className="form-control-file d-none" id="imagefile" onChange={this.fileChange} accept=".png, .jpg, .jpeg" />

                                                    </label>
                                                </div>

                                                <div style={{ height: 100, width: 100, background: 'center no-repeat', backgroundSize: 'cover', marginLeft: '30px' }} id="imagePreview"></div>

                                            </div>
                                        </div>

                                        <div className="col-12 mt-3">

                                            <button type="button" onClick={this.addInventory}
                                                className="btn-lg btn btn-success text-center float-right"
                                            >Add</button>


                                            {/* disabled={this.state.buttonDisable} */}
                                        </div>

                                    </div>
                                </form>
                            </div>

                        </div>
                        <Footer />
                    </div>

                </div>
        )
    }
}

const mapStateToProps = (state) => ({
    add: state.posts.add,

});


export default connect(mapStateToProps, { addProduct })(addInventory)
