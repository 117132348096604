import React, { Component } from 'react';
import $ from 'jquery';
var Popupstate = null
class ConfirmPopup extends Component {
    // constructor(props) {
    //     super(props);
    // this.state = {
    //     popupState : this.props.popupState(0)
    // };

    // }
    // componentDidMount(){

    // }
    // confirmPop(popupState){
    //     if(popupState){
    //         $('#confirmpopup').fadeIn(500);
    //     }else{
    //         $('#confirmpopup').fadeOut(500);
    //     }
    // }
    // conditionFalse = () => {
    //     this.props.popupState(1, false)
    //     // $('#confirmpopup').fadeOut(500);
    // }
    render() {
        // if(this.props.popupState(0)){
        //     $('#confirmpopup').fadeIn(500);
        // }else{
        //     $('#confirmpopup').fadeOut(500);
        // }
        // <div id="confirmpopup" style={{display:'none'}} >
        //     <button onClick={this.conditionFalse}>Hello</button>
        // </div>
        let popup = (
            <div id="confirmpopup" style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', backdropFilter: 'blur(5px)', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: '1050' }}>
                {/* <alertComponent showConfirmPopup = {this.showConfirmPopup}/> */}
                <div className="dropshadow rounded-1 p-0 overflow-hidden text-center" style={{ minWidth: '300px', width: '40%', maxWidth: '400px', height: '150px', backgroundColor: 'white', position: 'absolute', margin: 'auto', top: 'calc(50% - 75px)', left: 0, right: 0 }}>
                    <div className="d-none far fa-times-circle w-100 gradient-primary1 text-white m-0 py-5" style={{ fontSize: '60px' }}></div>
                    <div className="p-3">
                        <h2>Are you sure?</h2>
                        <p>Your Step Is Compelete </p>
                        <button onClick={this.props.onYes} className="btn btn-danger mr-2 rounded-1" style={{ width: '80px' }}>Yes</button>
                        <button onClick={this.props.onNo} className="btn btn-light rounded-1" style={{ width: '80px' }}>No</button>
                    </div>
                </div>
            </div>
        );
        if (this.props.isOpen1 === false) {
            popup = null;
            // alert('ConfirmPopup0 '+this.props.isOpen1)
        }
        return (
            <div>
                {popup}
            </div>
        );
    }
}
// const ConfirmPopup = props => {
//     return(
//         // style={{display:'none'}}
//         // <div id="confirmpopup"  >
//         //     <button onClick={props.handleClose(true)}></button>
//         // </div>
//         <div style={{backgroundColor: 'rgba(255, 255, 255, 0.2)',backdropFilter: 'blur(5px)',position: 'fixed',top: 0,left: 0,right: 0,bottom: 0,zIndex: '1050'}}>
//             <alertComponent showConfirmPopup = {this.showConfirmPopup}/>
//             <div className="dropshadow rounded-1 p-0 overflow-hidden text-center" style={{minWidth: '300px',width: '40%',maxWidth: '400px',height: '150px',backgroundColor: 'white',position: 'absolute',margin: 'auto',top: 'calc(50% - 75px)',left: 0,right: 0}}>
//                 <div className="d-none far fa-times-circle w-100 gradient-primary1 text-white m-0 py-5" style={{fontSize: '60px'}}></div>
//                 <div className="p-3">
//                     <h2>Are you sure?</h2>
//                     <p>Do you realy want to delete?</p>
//                     <button onClick={props.handleClose} className="btn btn-danger mr-2" style={{borderRadius: '50px', width: '80px'}}>Yes</button>
//                     <button onClick={props.handleClose} className="btn btn-light" style={{borderRadius: '50px', width: '80px'}}>No</button>
//                 </div>
//             </div>
//         </div>
//     );
// };



export default ConfirmPopup;