import React, { Component } from 'react'
import logo2 from '../../assets/img/logo/Logo.png'


export class footer extends Component {
    render() {
        return (

            <React.Fragment>
                <span className="w-100 d-flex justify-content-center py-2">
                    <span className="d-flex align-self-center">Powered by </span>
                    <a href="http://linked-things.com/" target="_blank">
                        <img src={logo2} className='footer-img' style={{height:'50px'}}/>
                    </a>
                    <span className="d-flex align-self-center">&copy; 2020 </span>
                    
                    
                </span>
            </React.Fragment>
        )
    }
}

export default footer
